import React, { useState } from 'react';
import { Organization } from '../../types/organization';
import { useAuth } from '../../contexts/AuthContext';
import { EmojiPicker } from '../EmojiPicker';

interface AddOrganizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (organization: Omit<Organization, 'id' | 'createdAt'>) => Promise<void>;
}

export const AddOrganizationModal: React.FC<AddOrganizationModalProps> = ({
  isOpen,
  onClose,
  onSave
}) => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    icon: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;
    
    setError(null);
    setLoading(true);

    try {
      await onSave({
        ...formData,
        ownerId: user.uid,
        members: [{
          userId: user.uid,
          email: user.email || 'unknown',
          role: 'owner',
          joinedAt: new Date()
        }]
      });
      onClose();
    } catch (error) {
      setError('Hiba történt a szervezet létrehozása során.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-dark">
      <div className="modal-content-dark">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-purple-primary">
            Új szervezet létrehozása
          </h2>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-purple-light rounded-full hover:bg-dark-300 transition-colors duration-200"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-900/50 text-red-300 rounded-md border border-red-500/50">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Szervezet neve
            </label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="input-dark"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Leírás (opcionális)
            </label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              className="input-dark"
              rows={3}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Ikon
            </label>
            <EmojiPicker
              currentEmoji={formData.icon}
              onSelect={(emoji) => setFormData(prev => ({ ...prev, icon: emoji }))}
            />
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-dark-300 text-gray-300 rounded-md hover:bg-dark-400 transition-colors duration-200"
            >
              Mégse
            </button>
            <button
              type="submit"
              className="btn-primary"
              disabled={loading}
            >
              {loading ? 'Létrehozás...' : 'Létrehozás'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}; 