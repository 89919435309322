import React from 'react';

const getCharacterType = (char: string) => {
  if (/[A-Z]/.test(char)) return 'uppercase';
  if (/[a-z]/.test(char)) return 'lowercase';
  if (/[0-9]/.test(char)) return 'number';
  return 'special';
};

interface ColorCodedPasswordProps {
  password: string;
  showLegend?: boolean;
}

export const ColorCodedPassword: React.FC<ColorCodedPasswordProps> = ({ 
  password,
  showLegend = false
}) => {
  return (
    <div>
      <div className="font-mono tracking-wider">
        {password.split('').map((char, index) => {
          const type = getCharacterType(char);
          const colorClass = {
            uppercase: 'text-blue-400',    // Nagybetűk kék színnel
            lowercase: 'text-green-400',   // Kisbetűk zöld színnel
            number: 'text-purple-400',     // Számok lila színnel
            special: 'text-yellow-400'     // Speciális karakterek sárga színnel
          }[type];

          return (
            <span key={index} className={colorClass} title={type}>
              {char}
            </span>
          );
        })}
      </div>

      {showLegend && (
        <div className="mt-2 flex gap-4 text-sm">
          <div className="flex items-center gap-1">
            <span className="w-3 h-3 rounded-full bg-blue-400" />
            <span className="text-gray-400">Nagybetű</span>
          </div>
          <div className="flex items-center gap-1">
            <span className="w-3 h-3 rounded-full bg-green-400" />
            <span className="text-gray-400">Kisbetű</span>
          </div>
          <div className="flex items-center gap-1">
            <span className="w-3 h-3 rounded-full bg-purple-400" />
            <span className="text-gray-400">Szám</span>
          </div>
          <div className="flex items-center gap-1">
            <span className="w-3 h-3 rounded-full bg-yellow-400" />
            <span className="text-gray-400">Speciális</span>
          </div>
        </div>
      )}
    </div>
  );
}; 