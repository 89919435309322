import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { BiometricService } from '../services/biometric';
import { EncryptionService } from '../services/encryption';

interface MasterPasswordContextType {
  masterPassword: string | null;
  setMasterPassword: (password: string) => void;
  lockTimeout: number | null;
  resetLockTimer: () => void;
  handleBiometricAuth: () => Promise<void>;
  saveBiometricAuth: (setQRCode: (url: string) => void) => Promise<void>;
}

const MasterPasswordContext = createContext<MasterPasswordContextType | undefined>(undefined);

const AUTO_LOCK_TIME = 1200; // másodperc

export const MasterPasswordProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [masterPassword, setMasterPasswordState] = useState<string | null>(null);
  const [lockTimeout, setLockTimeout] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  const resetLockTimer = useCallback(() => {
    if (masterPassword) {
      setLockTimeout(AUTO_LOCK_TIME);
    }
  }, [masterPassword]);

  useEffect(() => {
    if (lockTimeout === null) return;

    const timer = setInterval(() => {
      setLockTimeout(prev => {
        if (prev === null) return null;
        if (prev <= 1) {
          setMasterPasswordState(null);
          return null;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [lockTimeout]);

  useEffect(() => {
    if (masterPassword) {
      resetLockTimer();
    }
  }, [masterPassword, resetLockTimer]);

  const setMasterPassword = async (password: string) => {
    setMasterPasswordState(password);
    resetLockTimer();

    try {
      const isAvailable = await BiometricService.isBiometricAvailable();
      if (isAvailable) {
        const encryptedMP = await EncryptionService.encrypt(password, 'biometric_key');
        localStorage.setItem('encrypted_master_password', encryptedMP);
      }
    } catch (error) {
      console.error('Hiba a biometrikus kulcs mentése során:', error);
    }
  };

  const handleBiometricAuth = async () => {
    try {
      const isAvailable = await BiometricService.isBiometricAvailable();
      if (!isAvailable) {
        setError('Biometrikus azonosítás nem elérhető');
        return;
      }

      const isAuthenticated = await BiometricService.authenticate();
      if (isAuthenticated) {
        const encryptedMP = localStorage.getItem('encrypted_master_password');
        if (encryptedMP) {
          try {
            const decryptedMP = await EncryptionService.decrypt(encryptedMP, 'biometric_key');
            setMasterPassword(decryptedMP);
          } catch (error) {
            setError('Hiba történt a mester jelszó visszaállítása során');
          }
        }
      }
    } catch (error) {
      setError('Hiba történt a biometrikus azonosítás során');
    }
  };

  const saveBiometricAuth = async (setQRCode: (url: string) => void): Promise<void> => {
    try {
      if (!masterPassword) {
        setError('Nincs beállítva mester jelszó');
        return;
      }

      // Generálunk egy QR kódot
      const secret = Math.random().toString(36).substring(7);
      const qrCodeUrl = await BiometricService.generateQRCode(secret);

      // Elmentjük a titkosított mester jelszót és a QR kód titkos kulcsát
      const encryptedMP = await EncryptionService.encrypt(masterPassword, secret);
      localStorage.setItem('encrypted_master_password', encryptedMP);
      localStorage.setItem('biometric_secret', secret);

      // Megjelenítjük a QR kódot
      setQRCode(qrCodeUrl);
    } catch (error) {
      setError('Hiba történt a biometrikus azonosítás mentése során');
      throw error;
    }
  };

  return (
    <MasterPasswordContext.Provider value={{ 
      masterPassword, 
      setMasterPassword,
      lockTimeout,
      resetLockTimer,
      handleBiometricAuth,
      saveBiometricAuth
    }}>
      {children}
    </MasterPasswordContext.Provider>
  );
};

export const useMasterPassword = () => {
  const context = useContext(MasterPasswordContext);
  if (context === undefined) {
    throw new Error('useMasterPassword must be used within a MasterPasswordProvider');
  }
  return context;
}; 