import React, { useState, useEffect } from 'react';
import { Password } from '../../types/password';
import { Organization } from '../../types/organization';
import { useAuth } from '../../contexts/AuthContext';
import { getMyOrganizations, sharePasswordWithOrganization } from '../../services/organizations';
import { useMasterPassword } from '../../contexts/MasterPasswordContext';

interface SharePasswordModalProps {
  password: Password;
  onClose: () => void;
  onShare?: () => void;
}

export const SharePasswordModal: React.FC<SharePasswordModalProps> = ({
  password,
  onClose,
  onShare
}) => {
  const { user } = useAuth();
  const { masterPassword } = useMasterPassword();
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<string>('');
  const [permissions, setPermissions] = useState<'read' | 'write' | 'admin'>('read');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const loadOrganizations = async () => {
      if (!user) return;
      try {
        const orgs = await getMyOrganizations(user.uid);
        setOrganizations(orgs);
      } catch (error) {
        console.error('Hiba a szervezetek betöltése során:', error);
        setError('Nem sikerült betölteni a szervezeteket.');
      } finally {
        setLoading(false);
      }
    };

    loadOrganizations();
  }, [user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedOrgId || !masterPassword || !user) return;

    setError(null);
    setLoading(true);

    try {
      await sharePasswordWithOrganization({
        ...password,
        organizationId: selectedOrgId,
        sharedBy: user.email || 'Ismeretlen felhasználó',
        permissions,
        sharedAt: new Date()
      }, masterPassword);

      setSuccess(true);
      onShare?.();
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Hiba a jelszó megosztása során:', error);
      setError('Hiba történt a jelszó megosztása során.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-dark">
      <div className="modal-content-dark max-w-md">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-purple-primary">
            Jelszó megosztása
          </h2>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-purple-light rounded-full hover:bg-dark-300 transition-colors duration-200"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-900/50 text-red-300 rounded-md border border-red-500/50">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 p-3 bg-green-900/50 text-green-300 rounded-md border border-green-500/50 
            animate-fade-in flex items-center gap-2"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7"/>
            </svg>
            <span>Jelszó sikeresen megosztva!</span>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Szervezet kiválasztása
            </label>
            <select
              value={selectedOrgId}
              onChange={(e) => setSelectedOrgId(e.target.value)}
              className="input-dark"
              required
            >
              <option value="">Válassz szervezetet...</option>
              {organizations.map(org => (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Jogosultság
            </label>
            <select
              value={permissions}
              onChange={(e) => setPermissions(e.target.value as typeof permissions)}
              className="input-dark"
              required
            >
              <option value="read">Csak olvasható</option>
              <option value="write">Szerkeszthető</option>
              <option value="admin">Admin</option>
            </select>
            <p className="mt-1 text-sm text-gray-400">
              {permissions === 'admin' 
                ? 'Az admin jogosultság teljes hozzáférést biztosít a jelszóhoz.'
                : permissions === 'write'
                ? 'A szerkesztési jogosultság lehetővé teszi a jelszó módosítását.'
                : 'A csak olvasható jogosultság csak a jelszó megtekintését teszi lehetővé.'}
            </p>
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-dark-300 text-gray-300 rounded-md hover:bg-dark-400 transition-colors duration-200"
            >
              Mégse
            </button>
            <button
              type="submit"
              className="btn-primary"
              disabled={loading || !selectedOrgId}
            >
              {loading ? 'Megosztás...' : 'Megosztás'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}; 