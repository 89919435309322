import { db } from '../config/firebase';
import { collection, doc, getDoc, setDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { EncryptionService } from './encryption';

interface UserData {
  encryptedMasterPassword?: string;
  salt?: string;
  lastLogin?: Date;
}

interface FirestoreUserData {
  encryptedMasterPassword?: string;
  salt?: string;
  lastLogin?: Timestamp;
}

const usersCollection = collection(db, 'users');

// Segédfüggvény a mester jelszó titkosításához
const encryptMasterPassword = async (masterPassword: string): Promise<{ encrypted: string; salt: string }> => {
  const salt = crypto.getRandomValues(new Uint8Array(16));
  const saltString = Array.from(salt).map(b => b.toString(16).padStart(2, '0')).join('');
  
  // Használjuk az EncryptionService-t a mester jelszó titkosításához
  const encrypted = await EncryptionService.encrypt(masterPassword, saltString);
  
  return {
    encrypted,
    salt: saltString
  };
};

// Felhasználói adatok lekérése
export const getUserData = async (userId: string): Promise<UserData | null> => {
  const userDoc = await getDoc(doc(usersCollection, userId));
  if (!userDoc.exists()) return null;
  
  const data = userDoc.data() as FirestoreUserData;
  
  return {
    ...data,
    lastLogin: data.lastLogin?.toDate(),
  } as UserData;
};

// Új felhasználó létrehozása mester jelszóval
export const createUserWithMasterPassword = async (userId: string, masterPassword: string) => {
  const { encrypted, salt } = await encryptMasterPassword(masterPassword);
  
  await setDoc(doc(usersCollection, userId), {
    encryptedMasterPassword: encrypted,
    salt,
    lastLogin: Timestamp.fromDate(new Date())
  });
};

// Mester jelszó ellenőrzése
export const verifyMasterPassword = async (userId: string, masterPassword: string): Promise<boolean> => {
  const userData = await getUserData(userId);
  if (!userData?.encryptedMasterPassword || !userData?.salt) return false;

  try {
    // Próbáljuk visszafejteni a tárolt jelszót
    const decrypted = await EncryptionService.decrypt(
      userData.encryptedMasterPassword,
      userData.salt
    );
    
    // Ellenőrizzük, hogy egyezik-e a megadott jelszóval
    return decrypted === masterPassword;
  } catch (error) {
    console.error('Hiba a mester jelszó ellenőrzése során:', error);
    return false;
  }
};

// Mester jelszó frissítése
export const updateMasterPassword = async (userId: string, newMasterPassword: string) => {
  const { encrypted, salt } = await encryptMasterPassword(newMasterPassword);
  
  await updateDoc(doc(usersCollection, userId), {
    encryptedMasterPassword: encrypted,
    salt,
    lastLogin: new Date()
  });
};

// Utolsó bejelentkezés frissítése
export const updateLastLogin = async (userId: string) => {
  await updateDoc(doc(usersCollection, userId), {
    lastLogin: Timestamp.fromDate(new Date())
  });
}; 