import QRCode from 'qrcode';

export class BiometricService {
  static async generateQRCode(secret: string): Promise<string> {
    try {
      const url = `otpauth://totp/YouPass:${encodeURIComponent(secret)}?secret=${secret}&issuer=YouPass`;
      return await QRCode.toDataURL(url);
    } catch (error) {
      console.error('QR kód generálási hiba:', error);
      throw error;
    }
  }

  static async isBiometricAvailable(): Promise<boolean> {
    // Mindig elérhető, mivel QR kódot használunk
    return true;
  }

  static async authenticate(): Promise<boolean> {
    // Itt implementálhatjuk a QR kód beolvasását
    // Egyelőre csak visszaadjuk, hogy sikeres
    return true;
  }
} 