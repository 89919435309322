export const en = {
  common: {
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    cancel: 'Cancel',
    save: 'Save',
    share: 'Share',
    details: 'Details',
    copy: 'Copy',
    close: 'Close',
    search: 'Search',
    betaMessage: 'This is a beta version, please report any bugs you find',
  },
  passwords: {
    title: 'Passwords',
    empty: {
      title: 'No passwords yet',
      description: 'Start adding passwords using the + button'
    },
    noResults: 'No results found',
    fields: {
      title: 'Title',
      username: 'Username',
      password: 'Password',
      url: 'URL',
      ipAddress: 'IP address',
      notes: 'Notes',
      category: 'Category',
      tags: 'Tags'
    },
    notifications: {
      copied: {
        username: 'Username copied to clipboard!',
        password: 'Password copied to clipboard!',
        url: 'URL copied to clipboard!',
        ipAddress: 'IP address copied to clipboard!',
        notes: 'Notes copied!'
      },
      added: 'Password successfully added!',
      edited: 'Password successfully updated!',
      deleted: 'Password successfully deleted!',
      shared: 'Password successfully shared!'
    }
  },
  modals: {
    addPassword: {
      title: 'Add New Password',
      generatePassword: 'Generate Password',
      placeholders: {
        title: 'Enter password title',
        username: 'Enter username',
        password: 'Enter password',
        url: 'Enter URL',
        ipAddress: 'Enter IP address',
        notes: 'Notes...'
      }
    }
  },
  auth: {
    enterMasterPassword: 'Enter master password',
    masterPasswordPlaceholder: 'Master password...',
    unlock: 'Unlock',
    logout: 'Logout',
    logoutConfirm: 'Are you sure you want to log out?',
    subtitle: 'Secure Password Manager',
    login: 'Login',
    loginWithGoogle: 'Sign in with Google',
    acceptPrivacyPolicy: 'By signing in, you agree to the',
    privacyPolicy: 'Privacy Policy',
    features: 'Secure • Encrypted • Reliable'
  },
  privacy: {
    title: 'Privacy Policy',
    intro: {
      title: 'Introduction',
      content: 'YouPass is committed to protecting user data. This privacy policy details how we collect, use, and protect your personal information.'
    },
    dataCollection: {
      title: 'Data Collection and Usage',
      google: {
        title: 'Google Account',
        content: 'We only use your email address and profile picture for authentication.'
      },
      passwords: {
        title: 'Passwords',
        content: 'All passwords are stored encrypted, only you can access them.'
      }
    },
    security: {
      title: 'Security Warnings',
      points: [
        'Use a strong master password',
        'Never share your master password',
        'Keep backups of important passwords',
        'If you lose your master password, stored passwords cannot be recovered'
      ]
    },
    deletion: {
      title: 'Data Deletion',
      content: 'When you delete your account, all associated data is permanently removed from our system. This action is irreversible.'
    },
    contact: {
      title: 'Contact',
      content: 'For privacy-related questions, please contact us at: privacy@youpass.com'
    },
    lastUpdate: 'Last update'
  },
  masterPassword: {
    enter: 'Enter Master Password',
    setup: 'Set Up Master Password',
    placeholder: 'Master password...',
    confirmPlaceholder: 'Confirm master password...',
    set: 'Set Password',
    unlock: 'Unlock',
    understand: 'Yes, I understand',
    warning: '⚠️ If you lose your master password, stored passwords cannot be recovered!',
    success: 'Successfully saved!',
    warningTitle: 'Warning!',
    warningText: 'Changing your master password will delete all your existing passwords and settings as they were encrypted with your previous master password.',
    warningConfirm: 'Are you sure you want to continue?',
    password: 'Master password',
    newPassword: 'New master password',
    confirmPassword: 'Confirm master password',
    lengthRequirement: 'Password must be at least 8 characters long',
    havePassword: 'I already have a master password',
    noPassword: 'I don\'t have a master password yet',
    biometric: 'Biometric login'
  }
}; 