import { db } from '../config/firebase';
import { 
  collection, 
  addDoc, 
  getDocs, 
  query, 
  where,
  Timestamp,
  deleteDoc,
  doc,
  updateDoc
} from 'firebase/firestore';
import { Password, Category, Tag } from '../types/password';
import { EncryptionService } from './encryption';

// Jelszavak kezelése
export const passwordsCollection = collection(db, 'passwords');
export const categoriesCollection = collection(db, 'categories');
export const tagsCollection = collection(db, 'tags');

// Jelszavak lekérése
export const getPasswords = async (userId: string, masterPassword: string) => {
  const q = query(passwordsCollection, where("userId", "==", userId));
  const snapshot = await getDocs(q);
  
  const passwords = await Promise.all(
    snapshot.docs.map(async doc => {
      const data = doc.data();
      try {
        return {
          id: doc.id,
          ...data,
          password: await EncryptionService.decrypt(data.password, masterPassword),
          createdAt: data.createdAt?.toDate(),
          updatedAt: data.updatedAt?.toDate(),
        };
      } catch (error) {
        console.error(`Hiba a jelszó visszafejtése során (${doc.id}):`, error);
        return null;
      }
    })
  );

  return passwords.filter(p => p !== null) as Password[];
};

// Új jelszó hozzáadása
export const addPassword = async (
  password: Omit<Password, 'id' | 'createdAt' | 'updatedAt'>,
  masterPassword: string
) => {
  try {
    const now = Timestamp.now();
    const encryptedPassword = await EncryptionService.encrypt(
      password.password,
      masterPassword
    );

    const docRef = await addDoc(passwordsCollection, {
      ...password,
      password: encryptedPassword,
      createdAt: now,
      updatedAt: now,
    });
    
    return docRef.id;
  } catch (error) {
    console.error('Hiba a jelszó titkosítása során:', error);
    throw error;
  }
};

// Kategóriák kezelése
export const getCategories = async (userId: string) => {
  const q = query(categoriesCollection, where("userId", "==", userId));
  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  })) as Category[];
};

export const addCategory = async (category: Omit<Category, 'id'>) => {
  const docRef = await addDoc(categoriesCollection, category);
  return docRef.id;
};

// Címkék kezelése
export const getTags = async (userId: string) => {
  const q = query(tagsCollection, where("userId", "==", userId));
  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  })) as Tag[];
};

export const addTag = async (tag: Omit<Tag, 'id'>) => {
  const docRef = await addDoc(tagsCollection, tag);
  return docRef.id;
};

// Kategória törlése
export const deleteCategory = async (categoryId: string) => {
  const categoryRef = doc(db, 'categories', categoryId);
  await deleteDoc(categoryRef);
};

// Címke törlése
export const deleteTag = async (tagId: string) => {
  const tagRef = doc(db, 'tags', tagId);
  await deleteDoc(tagRef);
};

// Jelszó frissítése
export const updatePassword = async (
  passwordId: string,
  updates: Partial<Omit<Password, 'id' | 'createdAt' | 'userId'>>,
  masterPassword: string
) => {
  const now = Timestamp.now();
  const updateData = {
    ...updates,
    updatedAt: now,
  };

  if (updates.password) {
    updateData.password = await EncryptionService.encrypt(
      updates.password,
      masterPassword
    );
  }

  await updateDoc(doc(passwordsCollection, passwordId), updateData);
};

// Jelszó törlése
export const deletePassword = async (passwordId: string) => {
  await deleteDoc(doc(passwordsCollection, passwordId));
};

// Kategória műveletek
export const updateCategory = async (categoryId: string, updates: Partial<Omit<Category, 'id'>>) => {
  const categoryRef = doc(db, 'categories', categoryId);
  await updateDoc(categoryRef, {
    ...updates,
    updatedAt: new Date()
  });
};

// Címke műveletek
export const updateTag = async (tagId: string, updates: Partial<Omit<Tag, 'id'>>) => {
  const tagRef = doc(db, 'tags', tagId);
  await updateDoc(tagRef, {
    ...updates,
    updatedAt: new Date()
  });
}; 