import React, { useState, useEffect } from 'react';
import { Category, Tag } from '../types/password';
import { addCategory, addTag, deleteCategory, deleteTag } from '../services/firebase';
import { useAuth } from '../contexts/AuthContext';
import { useMasterPassword } from '../contexts/MasterPasswordContext';

interface SettingsProps {
  categories: Category[];
  tags: Tag[];
  onCategoryAdded: () => void;
  onTagAdded: () => void;
}

const TrashIcon = () => (
  <svg 
    className="w-5 h-5" 
    fill="none" 
    stroke="currentColor" 
    viewBox="0 0 24 24"
  >
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth={1.5} 
      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
    />
  </svg>
);

export const Settings: React.FC<SettingsProps> = ({
  categories,
  tags,
  onCategoryAdded,
  onTagAdded
}) => {
  const { user } = useAuth();
  const { masterPassword, saveBiometricAuth } = useMasterPassword();
  const [newCategory, setNewCategory] = useState('');
  const [newTag, setNewTag] = useState({ name: '', color: '#8b5cf6' });
  const [biometricEnabled, setBiometricEnabled] = useState(false);
  const [biometricLoading, setBiometricLoading] = useState(false);
  const [notification, setNotification] = useState<{
    type: 'success' | 'error';
    message: string;
  } | null>(null);
  const [qrCode, setQRCode] = useState<string | null>(null);

  useEffect(() => {
    setBiometricEnabled(!!localStorage.getItem('encrypted_master_password'));
  }, []);

  const handleAddCategory = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newCategory.trim() || !user) return;

    try {
      await addCategory({
        name: newCategory.trim(),
        userId: user.uid
      });
      setNewCategory('');
      onCategoryAdded();
    } catch (error) {
      console.error('Hiba a kategória hozzáadása során:', error);
    }
  };

  const handleAddTag = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newTag.name.trim() || !user) return;

    try {
      await addTag({
        name: newTag.name.trim(),
        color: newTag.color,
        userId: user.uid
      });
      setNewTag({ name: '', color: '#8b5cf6' });
      onTagAdded();
    } catch (error) {
      console.error('Hiba a címke hozzáadása során:', error);
    }
  };

  const handleDeleteCategory = async (categoryId: string) => {
    if (!window.confirm('Biztosan törölni szeretnéd ezt a kategóriát?')) return;

    try {
      await deleteCategory(categoryId);
      onCategoryAdded();
    } catch (error) {
      console.error('Hiba a kategória törlése során:', error);
    }
  };

  const handleDeleteTag = async (tagId: string) => {
    if (!window.confirm('Biztosan törölni szeretnéd ezt a címkét?')) return;

    try {
      await deleteTag(tagId);
      onTagAdded();
    } catch (error) {
      console.error('Hiba a címke törlése során:', error);
    }
  };

  const handleBiometricToggle = async () => {
    setBiometricLoading(true);
    try {
      if (biometricEnabled) {
        localStorage.removeItem('encrypted_master_password');
        setBiometricEnabled(false);
      } else {
        await saveBiometricAuth(setQRCode);
        setBiometricEnabled(true);
        setNotification({
          type: 'success',
          message: 'Biometrikus azonosítás sikeresen beállítva!'
        });
      }
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Hiba történt a biometrikus azonosítás beállítása során'
      });
    } finally {
      setBiometricLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 animate-fade-in">
      <h2 className="text-2xl font-bold text-purple-primary mb-6">Beállítások</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Kategóriák szekció */}
        <div className="card-dark p-6">
          <h3 className="text-lg font-semibold text-purple-light mb-4">Kategóriák</h3>
          
          <form onSubmit={handleAddCategory} className="mb-4">
            <div className="flex gap-2">
              <input
                type="text"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                placeholder="Új kategória neve"
                className="input-dark flex-1"
              />
              <button
                type="submit"
                className="btn-primary"
              >
                Hozzáadás
              </button>
            </div>
          </form>

          <div className="space-y-2">
            {categories.map((category) => (
              <div
                key={category.id}
                className="flex items-center justify-between p-2 bg-dark-300 rounded transition-colors duration-200 hover:bg-dark-400"
              >
                <span className="text-gray-200">{category.name}</span>
                <button 
                  onClick={() => handleDeleteCategory(category.id)}
                  className="text-gray-400 hover:text-red-500 transition-colors duration-200 p-1 rounded-full hover:bg-dark-400"
                >
                  <TrashIcon />
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Címkék szekció */}
        <div className="card-dark p-6">
          <h3 className="text-lg font-semibold text-purple-light mb-4">Címkék</h3>
          
          <form onSubmit={handleAddTag} className="mb-4">
            <div className="space-y-3">
              <div className="flex gap-2">
                <input
                  type="text"
                  value={newTag.name}
                  onChange={(e) => setNewTag({ ...newTag, name: e.target.value })}
                  placeholder="Új címke neve"
                  className="input-dark flex-1"
                />
                <input
                  type="color"
                  value={newTag.color}
                  onChange={(e) => setNewTag({ ...newTag, color: e.target.value })}
                  className="w-12 h-10 rounded-md bg-dark-300 border-dark-400 cursor-pointer"
                />
              </div>
              <button
                type="submit"
                className="btn-primary w-full"
              >
                Címke hozzáadása
              </button>
            </div>
          </form>

          <div className="flex flex-wrap gap-2">
            {tags.map((tag) => (
              <div
                key={tag.id}
                className="group flex items-center gap-2 px-3 py-1 rounded-full transition-all duration-200"
                style={{ 
                  backgroundColor: `${tag.color}20`,
                  borderColor: `${tag.color}40`,
                  borderWidth: '1px'
                }}
              >
                <span style={{ color: tag.color }}>{tag.name}</span>
                <button 
                  onClick={() => handleDeleteTag(tag.id)}
                  className="opacity-60 group-hover:opacity-100 transition-opacity duration-200 p-1"
                  style={{ color: tag.color }}
                >
                  <TrashIcon />
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Biometrikus azonosítás szekció */}
        <div className="card-dark p-6">
          <h3 className="text-lg font-semibold text-gray-200 mb-4">Biometrikus azonosítás</h3>
          <div className="flex items-center justify-between">
            <div>
              <p className="text-gray-300">Belépés biometrikus azonosítással</p>
              <p className="text-sm text-gray-400">
                Használja az ujjlenyomatát vagy arcfelismerést a belépéshez
              </p>
            </div>
            <button
              onClick={handleBiometricToggle}
              disabled={biometricLoading}
              className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors
                ${biometricEnabled ? 'bg-purple-primary' : 'bg-dark-300'}`}
            >
              <span
                className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform
                  ${biometricEnabled ? 'translate-x-6' : 'translate-x-1'}`}
              />
            </button>
          </div>
        </div>
      </div>
      {notification && (
        <div className={`fixed bottom-4 right-4 p-4 rounded-md shadow-lg
          ${notification.type === 'success' ? 'bg-green-900/50 text-green-300 border border-green-500/50' 
          : 'bg-red-900/50 text-red-300 border border-red-500/50'}`}
        >
          {notification.message}
        </div>
      )}
      {qrCode && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-dark-100 p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold text-gray-200 mb-4">
              Biometrikus azonosítás beállítása
            </h3>
            <p className="text-gray-400 mb-4">
              Olvassa be ezt a QR kódot az autentikátor alkalmazásával
            </p>
            <img src={qrCode} alt="QR Code" className="mb-4" />
            <button
              onClick={() => setQRCode(null)}
              className="btn-primary w-full"
            >
              Kész
            </button>
          </div>
        </div>
      )}
    </div>
  );
}; 