import React, { useState } from 'react';
import { Password, Category, Tag } from '../types/password';
import { CopyIcon, EyeIcon, EyeOffIcon } from './icons';
import { Notification, NotificationType } from './Notification';
import { ColorCodedPassword } from './ColorCodedPassword';


interface PasswordDetailsProps {
  password: Password;
  categories: Category[];
  tags: Tag[];
  onClose: () => void;
}

export const PasswordDetails: React.FC<PasswordDetailsProps> = ({
  password,
  categories,
  tags,
  onClose,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [notification, setNotification] = useState<{ type: NotificationType; message: string; } | null>(null);

  const handleCopy = (text: string, message: string) => {
    navigator.clipboard.writeText(text);
    setNotification({
      type: 'success',
      message: message
    });
    setTimeout(() => setNotification(null), 3000);
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-dark-100 rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold text-gray-200">{password.title}</h2>
            <button
              onClick={onClose}
              className="p-2 text-gray-400 hover:text-purple-light rounded-full hover:bg-dark-300"
            >
              ×
            </button>
          </div>

          <div className="space-y-6">
            {/* Felhasználónév */}
            <div>
              <h3 className="text-sm font-medium text-gray-400 mb-2">Felhasználónév</h3>
              <div className="flex items-center gap-2">
                <span className="text-gray-300">{password.username}</span>
                <button
                  onClick={() => handleCopy(password.username, 'Felhasználónév másolva!')}
                  className="p-1 text-gray-400 hover:text-purple-light transition-colors duration-200 rounded-full hover:bg-dark-300"
                >
                  <CopyIcon />
                </button>
              </div>
            </div>

            {/* Jelszó */}
            <div>
              <h3 className="text-sm font-medium text-gray-400 mb-2">Jelszó</h3>
              <div className="flex items-center gap-2">
                <div className="relative flex-1">
                  {showPassword ? (
                    <div className="input-dark pr-10 bg-dark-200 w-full py-2 px-3">
                      <ColorCodedPassword password={password.password} showLegend={true} />
                    </div>
                  ) : (
                    <input
                      type="password"
                      value={password.password}
                      readOnly
                      className="input-dark pr-10 bg-dark-200 w-full"
                    />
                  )}
                  <button
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-purple-light"
                  >
                    {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                  </button>
                </div>
                <button
                  onClick={() => handleCopy(password.password, 'Jelszó másolva!')}
                  className="p-2 text-gray-400 hover:text-purple-light transition-colors duration-200 rounded-full hover:bg-dark-300"
                >
                  <CopyIcon />
                </button>
              </div>
            </div>

            {/* URL */}
            {password.url && (
              <div>
                <h3 className="text-sm font-medium text-gray-400 mb-2">URL</h3>
                <div className="flex items-center gap-2">
                  <a
                    href={password.url.startsWith('http') ? password.url : `https://${password.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-purple-light hover:text-purple-primary transition-colors duration-200 
                      hover:underline truncate flex-1"
                  >
                    {password.url}
                  </a>
                  <button
                    onClick={() => handleCopy(password.url!, 'URL másolva!')}
                    className="p-1 text-gray-400 hover:text-purple-light transition-colors duration-200 rounded-full hover:bg-dark-300"
                  >
                    <CopyIcon />
                  </button>
                </div>
              </div>
            )}

            {/* IP Cím */}
            {password.ipAddress && (
              <div>
                <h3 className="text-sm font-medium text-gray-400 mb-2">IP Cím</h3>
                <div className="flex items-center gap-2">
                  <span className="text-gray-300 font-mono">{password.ipAddress}</span>
                  <button
                    onClick={() => handleCopy(password.ipAddress!, 'IP cím másolva!')}
                    className="p-1 text-gray-400 hover:text-purple-light transition-colors duration-200 rounded-full hover:bg-dark-300"
                  >
                    <CopyIcon />
                  </button>
                </div>
              </div>
            )}

            {/* Kategória */}
            {password.categoryId && (
              <div>
                <h3 className="text-sm font-medium text-gray-400 mb-2">Kategória</h3>
                <span className="px-3 py-1.5 rounded-full bg-dark-300 text-gray-300 border border-dark-400">
                  {categories.find(c => c.id === password.categoryId)?.icon}
                  {' '}
                  {categories.find(c => c.id === password.categoryId)?.name}
                </span>
              </div>
            )}

            {/* Címkék */}
            {password.tags.length > 0 && (
              <div>
                <h3 className="text-sm font-medium text-gray-400 mb-2">Címkék</h3>
                <div className="flex flex-wrap gap-2">
                  {password.tags.map((tagId) => {
                    const tag = tags.find(t => t.id === tagId);
                    return tag ? (
                      <span
                        key={tagId}
                        className="px-3 py-1.5 rounded-full"
                        style={{
                          backgroundColor: `${tag.color}20`,
                          color: tag.color,
                          borderColor: tag.color,
                          borderWidth: '1px'
                        }}
                      >
                        {tag.name}
                      </span>
                    ) : null;
                  })}
                </div>
              </div>
            )}

            {/* Jegyzetek */}
            {password.notes && (
              <div>
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-sm font-medium text-gray-400">Jegyzetek</h3>
                  <button
                    onClick={() => handleCopy(password.notes || '', 'Jegyzetek másolva!')}
                    className="p-1 text-gray-400 hover:text-purple-light transition-colors duration-200 rounded-full hover:bg-dark-300"
                    title="Jegyzetek másolása"
                  >
                    <CopyIcon />
                  </button>
                </div>
                <div className="bg-dark-200 p-3 rounded-md text-gray-300">
                  {password.notes.split('\n').map((line, i) => (
                    <p key={i} className="mb-1 last:mb-0">
                      {line || '\u00A0'}
                    </p>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Értesítés */}
          {notification && (
            <Notification
              type={notification.type}
              message={notification.message}
              onClose={() => setNotification(null)}
            />
          )}
        </div>
      </div>
    </div>
  );
}; 