import React, { useState, useEffect } from 'react';
import { useMasterPassword } from '../contexts/MasterPasswordContext';
import { useAuth } from '../contexts/AuthContext';
import { useLanguage } from '../contexts/LanguageContext';
import { 
  getUserData, 
  createUserWithMasterPassword, 
  verifyMasterPassword,
  updateLastLogin 
} from '../services/users';
import { BiometricService } from '../services/biometric';
import { LogoutIcon } from './icons';
import { LanguageSwitcher } from './LanguageSwitcher';

export const MasterPasswordPrompt: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [failedAttempts, setFailedAttempts] = useState(0);
  const { setMasterPassword, handleBiometricAuth } = useMasterPassword();
  const { user, logout } = useAuth();
  const [showWarning, setShowWarning] = useState(false);
  const [timeLeft, setTimeLeft] = useState(2000);
  const [success, setSuccess] = useState(false);
  const [isBiometricAvailable, setIsBiometricAvailable] = useState(false);
  const { t } = useLanguage();

  useEffect(() => {
    const checkUser = async () => {
      if (!user) return;
      
      const userData = await getUserData(user.uid);
      setIsNewUser(!userData?.encryptedMasterPassword);
      setLoading(false);
    };

    checkUser();
  }, [user]);

  useEffect(() => {
    if (timeLeft <= 0) {
      logout();
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft(prev => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, logout]);

  useEffect(() => {
    const checkBiometric = async () => {
      const isAvailable = await BiometricService.isBiometricAvailable();
      setIsBiometricAvailable(isAvailable);
    };
    checkBiometric();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);
    
    if (showWarning) {
      try {
        await createUserWithMasterPassword(user!.uid, password);
        setSuccess(true);
        setTimeout(async () => {
          await setMasterPassword(password);
        }, 1000);
        return;
      } catch (error) {
        console.error('Hiba a mester jelszó beállítása során:', error);
        setError('Hiba történt a mester jelszó beállítása során.');
      }
      return;
    }

    if (isNewUser) {
      if (password !== confirmPassword) {
        setError('A két jelszó nem egyezik!');
        return;
      }
      try {
        await createUserWithMasterPassword(user!.uid, password);
        setSuccess(true);
        setTimeout(async () => {
          await setMasterPassword(password);
        }, 1000);
      } catch (error) {
        setError('Hiba történt a mester jelszó beállítása során.');
      }
    } else {
      const isValid = await verifyMasterPassword(user!.uid, password);
      if (!isValid) {
        const newFailedAttempts = failedAttempts + 1;
        setFailedAttempts(newFailedAttempts);
        
        if (newFailedAttempts >= 2) {
          await logout();
          return;
        }
        
        setError(`Hibás mester jelszó! Még ${2 - newFailedAttempts} próbálkozása maradt.`);
        return;
      }

      if (isNewUser) {
        setShowWarning(true);
        return;
      }

      await updateLastLogin(user!.uid);
      await setMasterPassword(password);
      setFailedAttempts(0);
    }
  };

  if (loading) {
    return (
      <div className="modal-dark">
        <div className="text-purple-primary animate-pulse-slow">Betöltés...</div>
      </div>
    );
  }

  return (
    <div className="modal-dark">
      <div className="fixed top-4 right-4">
        <LanguageSwitcher />
      </div>

      <div className="modal-content-dark">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-purple-primary">
            {isNewUser ? t.masterPassword.setup : t.masterPassword.enter}
          </h2>
          <div className="flex items-center gap-2 text-sm text-gray-400">
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} 
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>{timeLeft}s</span>
          </div>
        </div>
        
        {error && (
          <div className="mb-4 p-3 bg-red-900/50 text-red-300 rounded-md border border-red-500/50">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 p-3 bg-green-900/50 text-green-300 rounded-md border border-green-500/50 
            animate-fade-in flex items-center gap-2"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
            <span>{t.masterPassword.success}</span>
          </div>
        )}

        {showWarning && (
          <div className="mb-6 p-4 bg-yellow-900/50 text-yellow-300 rounded-md border border-yellow-500/50">
            <h3 className="font-semibold mb-2">⚠️ {t.masterPassword.warningTitle}</h3>
            <p>{t.masterPassword.warningText}</p>
            <p className="mt-2">{t.masterPassword.warningConfirm}</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-300">
              {isNewUser ? t.masterPassword.newPassword : t.masterPassword.password}
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input-dark"
              required
              minLength={8}
            />
            {isNewUser && (
              <p className="mt-1 text-sm text-gray-400">
                {t.masterPassword.lengthRequirement}
              </p>
            )}
          </div>

          {isNewUser && (
            <div>
              <label className="block text-sm font-medium text-gray-300">
                {t.masterPassword.confirmPassword}
              </label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="input-dark"
                required
                minLength={8}
              />
            </div>
          )}

          <div className="flex justify-between gap-2">
            <button
              type="submit"
              className="btn-primary flex-1"
            >
              {showWarning ? t.masterPassword.understand : (isNewUser ? t.masterPassword.set : t.masterPassword.unlock)}
            </button>
            <button
              type="button"
              onClick={() => {
                if (window.confirm(t.auth.logoutConfirm)) {
                  logout();
                }
              }}
              className="p-2 text-gray-400 hover:text-purple-light transition-colors duration-200 rounded-full hover:bg-dark-300"
              title={t.auth.logout}
            >
              <LogoutIcon className="w-5 h-5" />
            </button>
          </div>

          {!showWarning && (
            <div className="text-center">
              <button
                type="button"
                onClick={() => setIsNewUser(!isNewUser)}
                className="text-sm text-purple-light hover:text-purple-primary transition-colors duration-200"
              >
                {isNewUser ? t.masterPassword.havePassword : t.masterPassword.noPassword}
              </button>
            </div>
          )}
        </form>

        {isBiometricAvailable && (
          <button
            onClick={handleBiometricAuth}
            className="btn-secondary flex items-center gap-2"
          >
            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
            </svg>
            {t.masterPassword.biometric}
          </button>
        )}
      </div>
    </div>
  );
}; 