import React, { useState, useEffect } from 'react';

interface PasswordGeneratorProps {
  onGenerate: (password: string) => void;
}

export const PasswordGenerator: React.FC<PasswordGeneratorProps> = ({ onGenerate }) => {
  const [length, setLength] = useState(32);
  const [options, setOptions] = useState({
    uppercase: true,
    lowercase: true,
    numbers: true,
    symbols: true
  });

  const generatePassword = () => {
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const symbols = '!@#$%^&*()_+-=[]{}|;:,.<>?';

    let chars = '';
    if (options.uppercase) chars += uppercase;
    if (options.lowercase) chars += lowercase;
    if (options.numbers) chars += numbers;
    if (options.symbols) chars += symbols;

    if (!chars) return;

    let password = '';
    for (let i = 0; i < length; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    onGenerate(password);
  };

  // Automatikus generálás, amikor változnak a beállítások
  useEffect(() => {
    generatePassword();
  }, [length, options.uppercase, options.lowercase, options.numbers, options.symbols]);

  return (
    <div className="flex flex-col space-y-4 bg-dark-200 p-3 rounded-md">
      <div className="flex items-center justify-between">
        <label className="text-sm font-medium text-gray-300">
          Hossz: {length} karakter
        </label>
        <input
          type="range"
          min="12"
          max="64"
          value={length}
          onChange={(e) => setLength(Number(e.target.value))}
          className="w-32 accent-purple-primary"
        />
      </div>

      <div className="grid grid-cols-2 gap-2">
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={options.uppercase}
            onChange={(e) => setOptions({ ...options, uppercase: e.target.checked })}
            className="checkbox-dark"
          />
          <span className="text-sm text-gray-300">Nagybetűk</span>
        </label>

        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={options.lowercase}
            onChange={(e) => setOptions({ ...options, lowercase: e.target.checked })}
            className="checkbox-dark"
          />
          <span className="text-sm text-gray-300">Kisbetűk</span>
        </label>

        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={options.numbers}
            onChange={(e) => setOptions({ ...options, numbers: e.target.checked })}
            className="checkbox-dark"
          />
          <span className="text-sm text-gray-300">Számok</span>
        </label>

        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={options.symbols}
            onChange={(e) => setOptions({ ...options, symbols: e.target.checked })}
            className="checkbox-dark"
          />
          <span className="text-sm text-gray-300">Szimbólumok</span>
        </label>
      </div>

      <button
        type="button"
        onClick={generatePassword}
        className="btn-primary w-full"
      >
        Új jelszó generálása
      </button>
    </div>
  );
}; 