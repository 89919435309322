import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { PrivacyPolicy } from './PrivacyPolicy';
import { useLanguage } from '../contexts/LanguageContext';
import { LanguageSwitcher } from './LanguageSwitcher';

export const Login: React.FC = () => {
  const { signInWithGoogle } = useAuth();
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const { t } = useLanguage();

  return (
    <>
      <div className="min-h-screen flex flex-col items-center justify-center bg-dark-200 px-4">
        <div className="fixed top-4 right-4">
          <LanguageSwitcher />
        </div>

        <div className="max-w-md w-full space-y-8 p-8 bg-dark-100 rounded-lg border border-dark-300 shadow-xl">
          <div className="text-center space-y-2">
            <div className="flex justify-center">
              <svg 
                className="w-16 h-16 text-purple-primary" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={1.5}
                  d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8V7a4 4 0 00-8 0v4h8z"
                />
              </svg>
            </div>
            <h2 className="text-3xl font-bold text-purple-primary">YouPass</h2>
            <p className="text-gray-400">{t.auth.subtitle}</p>
          </div>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-dark-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-dark-100 text-gray-400">{t.auth.login}</span>
            </div>
          </div>

          <button
            onClick={signInWithGoogle}
            className="w-full flex items-center justify-center px-4 py-3 rounded-lg
              bg-dark-300 hover:bg-dark-400 
              text-gray-200 hover:text-purple-light
              border border-dark-400 hover:border-purple-primary/30
              transition-all duration-200 transform hover:scale-[1.02]
              focus:outline-none focus:ring-2 focus:ring-purple-primary focus:ring-opacity-50"
          >
            <svg 
              className="w-5 h-5 mr-3" 
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"/>
            </svg>
            {t.auth.loginWithGoogle}
          </button>

          <p className="mt-8 text-center text-sm text-gray-400">
            {t.auth.acceptPrivacyPolicy}{' '}
            <button 
              onClick={() => setShowPrivacyPolicy(true)}
              className="text-purple-light hover:text-purple-primary transition-colors duration-200"
            >
              {t.auth.privacyPolicy}
            </button>
          </p>
        </div>

        <div className="mt-8 text-center text-sm text-gray-400">
          <p>{t.auth.features}</p>
        </div>
      </div>

      {showPrivacyPolicy && (
        <PrivacyPolicy onClose={() => setShowPrivacyPolicy(false)} />
      )}
    </>
  );
}; 