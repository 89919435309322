import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

export const PrivacyPolicy: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useLanguage();
  
  return (
    <div className="modal-dark">
      <div className="modal-content-dark max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-purple-primary">{t.privacy.title}</h2>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-purple-light rounded-full hover:bg-dark-300 transition-colors duration-200"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="space-y-6 text-gray-300">
          <section>
            <h3 className="text-lg font-semibold text-purple-light mb-2">{t.privacy.intro.title}</h3>
            <p>{t.privacy.intro.content}</p>
          </section>

          <section>
            <h3 className="text-lg font-semibold text-purple-light mb-2">{t.privacy.dataCollection.title}</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                <strong>{t.privacy.dataCollection.google.title}:</strong>{' '}
                {t.privacy.dataCollection.google.content}
              </li>
              <li>
                <strong>{t.privacy.dataCollection.passwords.title}:</strong>{' '}
                {t.privacy.dataCollection.passwords.content}
              </li>
            </ul>
          </section>

          <section>
            <h3 className="text-lg font-semibold text-purple-light mb-2">{t.privacy.security.title}</h3>
            <div className="bg-dark-300 p-4 rounded-md border border-dark-400">
              <ul className="list-disc pl-4 space-y-2 text-gray-300">
                {t.privacy.security.points.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
          </section>

          <section>
            <h3 className="text-lg font-semibold text-purple-light mb-2">{t.privacy.deletion.title}</h3>
            <p>{t.privacy.deletion.content}</p>
          </section>

          <section>
            <h3 className="text-lg font-semibold text-purple-light mb-2">{t.privacy.contact.title}</h3>
            <p>{t.privacy.contact.content}</p>
          </section>

          <div className="pt-4 border-t border-dark-300">
            <p className="text-sm text-gray-400">
              {t.privacy.lastUpdate}: {new Date().toLocaleDateString()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}; 