import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

export const BetaBanner: React.FC = () => {
  const { t } = useLanguage();
  
  return (
    <div className="bg-purple-primary/10 border-b border-purple-primary/20">
      <div className="container mx-auto px-4 py-1">
        <p className="text-xs text-center text-purple-light flex items-center justify-center gap-2">
          <span className="inline-flex items-center px-2 py-0.5 rounded-full text-[10px] font-medium bg-purple-primary/20">
            BETA
          </span>
          {t.common.betaMessage}
        </p>
      </div>
    </div>
  );
}; 