import React from 'react';

export type NotificationType = 'success' | 'error';

interface NotificationProps {
  type: NotificationType;
  message: string;
  onClose?: () => void;
}

export const Notification: React.FC<NotificationProps> = ({ type, message, onClose }) => {
  const bgColor = type === 'success' ? 'bg-green-900/50' : 'bg-red-900/50';
  const borderColor = type === 'success' ? 'border-green-500/50' : 'border-red-500/50';
  const textColor = type === 'success' ? 'text-green-300' : 'text-red-300';

  return (
    <div className={`fixed bottom-4 right-4 p-4 ${bgColor} ${textColor} rounded-md border ${borderColor} 
      shadow-lg animate-slide-up flex items-center gap-3 max-w-sm z-50`}
    >
      {type === 'success' ? (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7"/>
        </svg>
      ) : (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
        </svg>
      )}
      <span>{message}</span>
      {onClose && (
        <button 
          onClick={onClose}
          className="ml-auto p-1 hover:bg-black/20 rounded-full transition-colors duration-200"
        >
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      )}
    </div>
  );
}; 