import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Organization } from '../../types/organization';
import { getMyOrganizations, createOrganization, deleteOrganization, updateOrganization } from '../../services/organizations';
import { AddOrganizationModal } from './AddOrganizationModal';
import { Notification } from '../../components/Notification';
import { EditIcon, TrashIcon } from '../../components/icons';
import { EditOrganizationModal } from './EditOrganizationModal';

export const OrganizationList: React.FC = () => {
  const { user } = useAuth();
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [loading, setLoading] = useState(true);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [notification, setNotification] = useState<{ type: 'success' | 'error'; message: string } | null>(null);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    const loadOrganizations = async () => {
      if (!user) return;
      try {
        const orgs = await getMyOrganizations(user.uid);
        setOrganizations(orgs);
      } catch (error) {
        console.error('Hiba a szervezetek betöltése során:', error);
      } finally {
        setLoading(false);
      }
    };

    loadOrganizations();
  }, [user]);

  const handleCreateOrganization = async (organization: Omit<Organization, 'id' | 'createdAt'>) => {
    try {
      await createOrganization(organization);
      // Frissítjük a listát
      if (user) {
        const orgs = await getMyOrganizations(user.uid);
        setOrganizations(orgs);
      }
      setNotification({
        type: 'success',
        message: 'Szervezet sikeresen létrehozva!'
      });
      setTimeout(() => setNotification(null), 3000);
    } catch (error) {
      console.error('Hiba a szervezet létrehozása során:', error);
      setNotification({
        type: 'error',
        message: 'Hiba történt a szervezet létrehozása során.'
      });
      setTimeout(() => setNotification(null), 3000);
    }
  };

  const handleDeleteOrganization = async (orgId: string) => {
    if (!window.confirm('Biztosan törölni szeretnéd ezt a szervezetet?')) return;

    try {
      await deleteOrganization(orgId);
      setOrganizations(prev => prev.filter(org => org.id !== orgId));
      setNotification({
        type: 'success',
        message: 'Szervezet sikeresen törölve!'
      });
      setTimeout(() => setNotification(null), 3000);
    } catch (error) {
      console.error('Hiba a szervezet törlése során:', error);
      setNotification({
        type: 'error',
        message: 'Hiba történt a szervezet törlése során.'
      });
      setTimeout(() => setNotification(null), 3000);
    }
  };

  const handleEditOrganization = async (
    orgId: string,
    updates: Partial<Omit<Organization, 'id' | 'createdAt'>>
  ) => {
    try {
      await updateOrganization(orgId, updates);
      // Frissítjük a listát
      if (user) {
        const orgs = await getMyOrganizations(user.uid);
        setOrganizations(orgs);
      }
      setIsEditModalOpen(false);
      setSelectedOrganization(null);
      setNotification({
        type: 'success',
        message: 'Szervezet sikeresen módosítva!'
      });
      setTimeout(() => setNotification(null), 3000);
    } catch (error) {
      console.error('Hiba a szervezet módosítása során:', error);
      setNotification({
        type: 'error',
        message: 'Hiba történt a szervezet módosítása során.'
      });
      setTimeout(() => setNotification(null), 3000);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-purple-primary animate-pulse-slow">Betöltés...</div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto w-full">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl sm:text-2xl font-bold text-purple-primary">Szervezetek</h2>
        <button 
          onClick={() => setIsAddModalOpen(true)}
          className="btn-primary"
        >
          <svg 
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M12 4v16m8-8H4" 
            />
          </svg>
        </button>
      </div>

      {organizations.length === 0 ? (
        <div className="text-center py-12">
          <div className="mb-4">
            <svg 
              className="w-16 h-16 mx-auto text-purple-primary/30"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={1.5}
                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
          </div>
          <h3 className="text-lg font-medium text-purple-primary mb-2">
            Nincsenek még szervezetek
          </h3>
          <p className="text-gray-400 mb-6">
            Hozz létre egy szervezetet a jelszavak megosztásához
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {organizations.map(org => (
            <div 
              key={org.id}
              className="card-dark p-4 hover:border-purple-primary/30 transition-colors duration-200 cursor-pointer"
              onClick={() => {/* TODO: Navigálás a szervezet részletes nézetéhez */}}
            >
              <div className="flex items-center gap-3 mb-3">
                {org.icon ? (
                  <span className="text-2xl">{org.icon}</span>
                ) : (
                  <div className="w-8 h-8 rounded-full bg-purple-primary/10 flex items-center justify-center text-purple-primary">
                    {org.name.charAt(0).toUpperCase()}
                  </div>
                )}
                <h3 className="text-lg font-semibold text-gray-100">{org.name}</h3>
              </div>
              {org.description && (
                <p className="text-gray-400 text-sm mb-3">{org.description}</p>
              )}
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <svg 
                  className="w-4 h-4" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={1.5}
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
                <span>{org.members.length} tag</span>
              </div>
              {org.ownerId === user?.uid && (
                <div className="flex gap-2">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedOrganization(org);
                      setIsEditModalOpen(true);
                    }}
                    className="p-1 text-gray-400 hover:text-purple-light transition-colors duration-200 rounded-full hover:bg-dark-300"
                  >
                    <EditIcon />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteOrganization(org.id);
                    }}
                    className="p-1 text-gray-400 hover:text-red-500 transition-colors duration-200 rounded-full hover:bg-dark-300"
                  >
                    <TrashIcon />
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      <AddOrganizationModal 
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSave={handleCreateOrganization}
      />

      {selectedOrganization && (
        <EditOrganizationModal
          organization={selectedOrganization}
          isOpen={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
            setSelectedOrganization(null);
          }}
          onSave={handleEditOrganization}
        />
      )}

      {notification && (
        <Notification
          type={notification.type}
          message={notification.message}
          onClose={() => setNotification(null)}
        />
      )}
    </div>
  );
}; 