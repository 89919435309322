import React, { useState } from 'react';
import { Password, Category, Tag } from '../types/password';
import { useAuth } from '../contexts/AuthContext';
import { PasswordGenerator } from './PasswordGenerator';
import { addCategory, addTag, updateCategory, deleteCategory, updateTag, deleteTag } from '../services/firebase';
import { EditIcon, TrashIcon } from './icons/index';
import { InlineEdit } from './InlineEdit';
import { PREDEFINED_COLORS } from '../constants/colors';

interface AddPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (password: Omit<Password, 'id' | 'createdAt' | 'updatedAt'>) => void;
  categories: Category[];
  tags: Tag[];
  onCategoryAdded: () => void;
  onTagAdded: () => void;
}

const EyeIcon = () => (
  <svg 
    className="w-5 h-5" 
    fill="none" 
    stroke="currentColor" 
    viewBox="0 0 24 24"
  >
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth={1.5} 
      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    />
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth={1.5} 
      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
    />
  </svg>
);

const EyeOffIcon = () => (
  <svg 
    className="w-5 h-5" 
    fill="none" 
    stroke="currentColor" 
    viewBox="0 0 24 24"
  >
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth={1.5} 
      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
    />
  </svg>
);

export const AddPasswordModal: React.FC<AddPasswordModalProps> = ({
  isOpen,
  onClose,
  onSave,
  categories,
  tags,
  onCategoryAdded,
  onTagAdded
}) => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    title: '',
    username: '',
    password: '',
    url: '',
    ipAddress: '',
    notes: '',
    categoryId: '',
    tags: [] as string[]
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isGeneratorOpen, setIsGeneratorOpen] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [newTag, setNewTag] = useState({ name: '', color: '#8b5cf6' });
  const [editingCategoryId, setEditingCategoryId] = useState<string | null>(null);
  const [editingTagId, setEditingTagId] = useState<string | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    onSave({
      ...formData,
      userId: user.uid
    });

    setFormData({
      title: '',
      username: '',
      password: '',
      url: '',
      ipAddress: '',
      notes: '',
      categoryId: '',
      tags: []
    });
    onClose();
  };

  const handleAddCategory = async () => {
    if (!user || !newCategory.trim()) return;
    
    try {
      await addCategory({
        name: newCategory.trim(),
        userId: user.uid,
        icon: '📁'
      });
      setNewCategory('');
      onCategoryAdded();
    } catch (error) {
      console.error('Hiba a kategória létrehozása során:', error);
    }
  };

  const handleAddTag = async () => {
    if (!user || !newTag.name.trim()) return;
    
    try {
      await addTag({
        name: newTag.name.trim(),
        color: newTag.color,
        userId: user.uid
      });
      setNewTag({ name: '', color: '#8b5cf6' });
      onTagAdded();
    } catch (error) {
      console.error('Hiba a címke létrehozása során:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-dark">
      <div className="modal-content-dark-wide max-h-[90vh] overflow-y-auto mx-2">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg sm:text-xl font-bold text-purple-primary">
            Új jelszó hozzáadása
          </h2>
          <button
            type="button"
            onClick={onClose}
            className="sm:hidden p-2 text-gray-400 hover:text-purple-light rounded-full hover:bg-dark-300"
          >
            ×
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">Jelszó</label>
            <div className="flex gap-2">
              <div className="flex-1">
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={formData.password}
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    className="input-dark w-full pr-10"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-purple-light transition-colors duration-200"
                  >
                    {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                  </button>
                </div>
              </div>
              <button
                type="button"
                onClick={() => setIsGeneratorOpen(!isGeneratorOpen)}
                className="px-3 py-2 bg-dark-300 text-gray-300 rounded-md hover:bg-dark-400 transition-colors duration-200 flex items-center gap-2"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                </svg>
                Generálás
              </button>
            </div>
            {isGeneratorOpen && (
              <div className="mt-2">
                <PasswordGenerator onGenerate={(password) => {
                  setFormData({ ...formData, password });
                  setShowPassword(true);
                }} />
              </div>
            )}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">Cím</label>
              <input
                type="text"
                className="input-dark w-full"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">Felhasználónév</label>
              <input
                type="text"
                className="input-dark w-full"
                value={formData.username}
                onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">URL</label>
              <input
                type="url"
                className="input-dark w-full"
                value={formData.url}
                onChange={(e) => setFormData({ ...formData, url: e.target.value })}
                placeholder="https://"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">IP Cím</label>
              <input
                type="text"
                className="input-dark w-full"
                value={formData.ipAddress}
                onChange={(e) => setFormData({ ...formData, ipAddress: e.target.value })}
                placeholder="192.168.1.1"
                pattern="^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
                title="Kérlek adj meg egy érvényes IP címet (pl.: 192.168.1.1)"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">Jegyzetek</label>
            <textarea
              className="input-dark w-full min-h-[80px]"
              value={formData.notes}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
              rows={3}
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-4">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-300">Kategória</label>
              <div className="bg-dark-200 p-3 rounded-md">
                <div className="flex flex-wrap gap-2 mb-2">
                  {categories.map((category) => (
                    <div key={category.id} className="group relative">
                      {editingCategoryId === category.id ? (
                        <InlineEdit
                          value={category.name}
                          onSave={(newName) => {
                            updateCategory(category.id, { name: newName })
                              .then(() => {
                                setEditingCategoryId(null);
                                onCategoryAdded();
                              });
                          }}
                          onCancel={() => setEditingCategoryId(null)}
                        />
                      ) : (
                        <>
                          <label
                            className={`inline-flex items-center px-3 py-1.5 rounded-full cursor-pointer
                              transition-all duration-200 border group-hover:border-opacity-0
                              ${formData.categoryId === category.id 
                                ? 'bg-purple-primary/20 border-purple-primary text-purple-primary' 
                                : 'bg-dark-300 border-dark-400 text-gray-400 hover:border-purple-primary/50'
                              }`}
                          >
                            <input
                              type="radio"
                              className="hidden"
                              name="category"
                              value={category.id}
                              checked={formData.categoryId === category.id}
                              onChange={(e) => setFormData({ ...formData, categoryId: e.target.value })}
                            />
                            {category.icon && <span className="mr-1">{category.icon}</span>}
                            {category.name}
                          </label>
                          <div className="absolute right-0 top-0 -mt-2 -mr-2 hidden group-hover:flex gap-1">
                            <button
                              type="button"
                              onClick={() => setEditingCategoryId(category.id)}
                              className="p-1 bg-dark-100 rounded-full text-gray-400 hover:text-purple-light"
                            >
                              <EditIcon className="w-3 h-3" />
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                if (window.confirm('Biztosan törlöd ezt a kategóriát?')) {
                                  deleteCategory(category.id)
                                    .then(() => onCategoryAdded());
                                }
                              }}
                              className="p-1 bg-dark-100 rounded-full text-gray-400 hover:text-red-500"
                            >
                              <TrashIcon className="w-3 h-3" />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flex gap-2 mt-3 pt-3 border-t border-dark-300">
                  <input
                    type="text"
                    value={newCategory}
                    onChange={(e) => setNewCategory(e.target.value)}
                    placeholder="Új kategória..."
                    className="input-dark text-sm flex-1"
                  />
                  <button
                    type="button"
                    onClick={handleAddCategory}
                    disabled={!newCategory.trim()}
                    className="px-3 py-1 bg-dark-300 text-gray-300 rounded-md hover:bg-dark-400 
                      transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-300">Címkék</label>
              <div className="bg-dark-200 p-3 rounded-md">
                <div className="flex flex-wrap gap-2 mb-2">
                  {tags.map((tag) => (
                    <div key={tag.id} className="group relative">
                      {editingTagId === tag.id ? (
                        <InlineEdit
                          value={tag.name}
                          onSave={(newName) => {
                            updateTag(tag.id, { name: newName })
                              .then(() => {
                                setEditingTagId(null);
                                onTagAdded();
                              });
                          }}
                          onCancel={() => setEditingTagId(null)}
                        />
                      ) : (
                        <>
                          <label
                            className={`inline-flex items-center px-3 py-1.5 rounded-full cursor-pointer
                              transition-all duration-200 border group-hover:border-opacity-0
                              ${formData.tags.includes(tag.id)
                                ? 'text-gray-300'
                                : 'border-dark-400 text-gray-400 hover:border-purple-primary/50'
                              }`}
                            style={{
                              backgroundColor: formData.tags.includes(tag.id) ? `${tag.color}20` : '',
                              borderColor: formData.tags.includes(tag.id) ? tag.color : '',
                              color: formData.tags.includes(tag.id) ? tag.color : ''
                            }}
                          >
                            <input
                              type="checkbox"
                              className="hidden"
                              checked={formData.tags.includes(tag.id)}
                              onChange={(e) => {
                                const newTags = e.target.checked
                                  ? [...formData.tags, tag.id]
                                  : formData.tags.filter((id) => id !== tag.id);
                                setFormData({ ...formData, tags: newTags });
                              }}
                            />
                            {tag.name}
                          </label>
                          <div className="absolute right-0 top-0 -mt-2 -mr-2 hidden group-hover:flex gap-1">
                            <button
                              type="button"
                              onClick={() => setEditingTagId(tag.id)}
                              className="p-1 bg-dark-100 rounded-full text-gray-400 hover:text-purple-light"
                            >
                              <EditIcon className="w-3 h-3" />
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                if (window.confirm('Biztosan törlöd ezt a címkét?')) {
                                  deleteTag(tag.id)
                                    .then(() => onTagAdded());
                                }
                              }}
                              className="p-1 bg-dark-100 rounded-full text-gray-400 hover:text-red-500"
                            >
                              <TrashIcon className="w-3 h-3" />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flex gap-2 mt-3 pt-3 border-t border-dark-300">
                  <input
                    type="text"
                    value={newTag.name}
                    onChange={(e) => setNewTag({ ...newTag, name: e.target.value })}
                    placeholder="Új címke..."
                    className="input-dark text-sm flex-1"
                  />
                  <div className="relative group">
                    <input
                      type="color"
                      value={newTag.color}
                      onChange={(e) => setNewTag({ ...newTag, color: e.target.value })}
                      className="w-10 h-8 rounded bg-dark-300 border border-dark-400 cursor-pointer"
                    />
                    <div className="absolute right-0 bottom-[80%] bg-dark-100 border border-dark-300 
                      rounded-md shadow-lg p-3 hidden group-hover:grid grid-cols-4 gap-2 z-10 min-w-[120px]"
                    >
                      {PREDEFINED_COLORS.map(color => (
                        <button
                          key={color.value}
                          type="button"
                          onClick={() => setNewTag(prev => ({ ...prev, color: color.value }))}
                          className="w-7 h-7 rounded-full hover:scale-110 transition-transform duration-200
                            border-2 border-dark-300 hover:border-dark-400"
                          style={{ backgroundColor: color.value }}
                          title={color.name}
                        />
                      ))}
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={handleAddTag}
                    disabled={!newTag.name.trim()}
                    className="px-3 py-1 bg-dark-300 text-gray-300 rounded-md hover:bg-dark-400 
                      transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col-reverse sm:flex-row justify-end gap-3 sm:gap-2 mt-8 sm:mt-6">
            <button
              type="button"
              onClick={onClose}
              className="w-full sm:w-auto px-4 py-3 sm:py-2 bg-dark-300 text-gray-300 
                rounded-md hover:bg-dark-400 transition-colors duration-200
                text-base sm:text-sm"
            >
              Mégse
            </button>
            <button
              type="submit"
              className="w-full sm:w-auto btn-primary py-3 sm:py-2 text-base sm:text-sm"
            >
              Mentés
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}; 