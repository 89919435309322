export const hu = {
  common: {
    add: 'Hozzáadás',
    edit: 'Szerkesztés',
    delete: 'Törlés',
    cancel: 'Mégse',
    save: 'Mentés',
    share: 'Megosztás',
    details: 'Részletek',
    copy: 'Másolás',
    close: 'Bezárás',
    search: 'Keresés',
    betaMessage: 'Ez egy béta verzió, kérjük jelezze ha hibát talál',
  },
  passwords: {
    title: 'Jelszavak',
    empty: {
      title: 'Nincsenek még jelszavak',
      description: 'Kezdj el jelszavakat hozzáadni a + gomb segítségével'
    },
    noResults: 'Nincs találat a keresésre',
    fields: {
      title: 'Cím',
      username: 'Felhasználónév',
      password: 'Jelszó',
      url: 'URL',
      ipAddress: 'IP cím',
      notes: 'Jegyzetek',
      category: 'Kategória',
      tags: 'Címkék'
    },
    notifications: {
      copied: {
        username: 'Felhasználónév vágólapra másolva!',
        password: 'Jelszó vágólapra másolva!',
        url: 'URL vágólapra másolva!',
        ipAddress: 'IP cím vágólapra másolva!',
        notes: 'Jegyzetek másolva!'
      },
      added: 'Jelszó sikeresen hozzáadva!',
      edited: 'Jelszó sikeresen módosítva!',
      deleted: 'Jelszó sikeresen törölve!',
      shared: 'Jelszó sikeresen megosztva!'
    }
  },
  modals: {
    addPassword: {
      title: 'Új jelszó hozzáadása',
      generatePassword: 'Jelszó generálása',
      placeholders: {
        title: 'Add meg a jelszó címét',
        username: 'Add meg a felhasználónevet',
        password: 'Add meg a jelszót',
        url: 'Add meg az URL-t',
        ipAddress: 'Add meg az IP címet',
        notes: 'Jegyzetek...'
      }
    }
  },
  auth: {
    enterMasterPassword: 'Add meg a mesterjelszót',
    masterPasswordPlaceholder: 'Mesterjelszó...',
    unlock: 'Feloldás',
    logout: 'Kijelentkezés',
    logoutConfirm: 'Biztosan ki szeretnél jelentkezni?',
    subtitle: 'Biztonságos jelszókezelő',
    login: 'Bejelentkezés',
    loginWithGoogle: 'Bejelentkezés Google fiókkal',
    acceptPrivacyPolicy: 'A bejelentkezéssel elfogadod az',
    privacyPolicy: 'Adatvédelmi irányelveket',
    features: 'Biztonságos • Titkosított • Megbízható'
  },
  privacy: {
    title: 'Adatvédelmi irányelvek',
    intro: {
      title: 'Bevezetés',
      content: 'A YouPass elkötelezett a felhasználók adatainak védelme mellett. Ez az adatvédelmi nyilatkozat részletezi, hogyan gyűjtjük, használjuk és védjük az Ön személyes adatait.'
    },
    dataCollection: {
      title: 'Adatgyűjtés és felhasználás',
      google: {
        title: 'Google fiók',
        content: 'A bejelentkezéshez csak az email címét és profilképét használjuk.'
      },
      passwords: {
        title: 'Jelszavak',
        content: 'Minden jelszó titkosítva van tárolva, kizárólag Ön férhet hozzá.'
      }
    },
    security: {
      title: 'Biztonsági figyelmeztetések',
      points: [
        'Használjon erős mesterjelszót',
        'Ne ossza meg mesterjelszavát senkivel',
        'Készítsen biztonsági mentést a fontos jelszavairól',
        'A mester jelszó elvesztése esetén a tárolt jelszavak visszaállíthatatlanul elvesznek'
      ]
    },
    deletion: {
      title: 'Adattörlés',
      content: 'Fiókja törlésekor minden Önhöz kapcsolódó adat véglegesen törlődik rendszerünkből. A törlés visszavonhatatlan.'
    },
    contact: {
      title: 'Kapcsolat',
      content: 'Adatvédelemmel kapcsolatos kérdéseivel forduljon hozzánk bizalommal a következő email címen: privacy@youpass.com'
    },
    lastUpdate: 'Utolsó frissítés'
  },
  masterPassword: {
    enter: 'Add meg a mesterjelszót',
    setup: 'Állítsd be a mesterjelszót',
    placeholder: 'Mesterjelszó...',
    confirmPlaceholder: 'Mesterjelszó megerősítése...',
    set: 'Beállítás',
    unlock: 'Feloldás',
    understand: 'Igen, megértettem',
    warning: '⚠️ A mesterjelszó elvesztése esetén a tárolt jelszavak visszaállíthatatlanul elvesznek!',
    success: 'Sikeres mentés!',
    warningTitle: 'Figyelem!',
    warningText: 'A mester jelszó módosításával az összes eddigi jelszava és beállítása törlődni fog, mivel azok az előző mester jelszóval voltak titkosítva.',
    warningConfirm: 'Biztosan folytatja?',
    password: 'Mester jelszó',
    newPassword: 'Új mester jelszó',
    confirmPassword: 'Mester jelszó megerősítése',
    lengthRequirement: 'A jelszónak legalább 8 karakter hosszúnak kell lennie',
    havePassword: 'Van már mester jelszavam',
    noPassword: 'Még nincs mester jelszavam',
    biometric: 'Biometrikus belépés'
  }
}; 