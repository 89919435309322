import React, { useState } from 'react';

const commonEmojis = [
  '📁', '🔒', '🔑', '🏢', '🏗️', '🏪', '🏬', '🏭', '🏰', '🏛️',
  '💼', '🗄️', '📊', '📈', '📋', '📌', '📎', '✨', '💫', '⭐',
  '🌟', '💡', '🔔', '🎯', '🎨', '🎭', '🎪', '🎬', '📱', '💻'
];

interface EmojiPickerProps {
  onSelect: (emoji: string) => void;
  currentEmoji?: string;
}

export const EmojiPicker: React.FC<EmojiPickerProps> = ({ onSelect, currentEmoji }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="input-dark w-full text-left flex items-center gap-2"
      >
        <span className="text-xl">{currentEmoji || '📁'}</span>
        <span className="text-gray-400 text-sm">
          {currentEmoji ? 'Ikon módosítása' : 'Ikon választása'}
        </span>
      </button>

      {isOpen && (
        <div className="absolute z-50 mt-1 p-2 bg-dark-100 rounded-md border border-dark-300 shadow-lg grid grid-cols-6 gap-1">
          {commonEmojis.map(emoji => (
            <button
              key={emoji}
              type="button"
              onClick={() => {
                onSelect(emoji);
                setIsOpen(false);
              }}
              className={`p-2 text-xl hover:bg-dark-300 rounded-md transition-colors duration-200
                ${currentEmoji === emoji ? 'bg-dark-300' : ''}`}
            >
              {emoji}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}; 