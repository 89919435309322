import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useMasterPassword } from '../contexts/MasterPasswordContext';
import { Password, Category, Tag } from '../types/password';
import { AddPasswordModal } from './AddPasswordModal';
import { getPasswords, addPassword, updatePassword, deletePassword, getCategories, getTags } from '../services/firebase';
import { EditPasswordModal } from './EditPasswordModal';
import { Notification, NotificationType } from './Notification';
import { SharePasswordModal } from './organizations/SharePasswordModal';
import { PasswordDetails } from './PasswordDetails';
import { 
  InfoIcon, 
  EditIcon, 
  TrashIcon, 
  ShareIcon, 
  CopyIcon, 
  EyeIcon, 
  EyeOffIcon,
  SearchIcon,
  GridIcon,
  ListIcon,
  LockIcon
} from './icons';
import { ColorCodedPassword } from './ColorCodedPassword';

interface PasswordListProps {
  categories: Category[];
  tags: Tag[];
  onCategoryAdded: () => void;
  onTagAdded: () => void;
}

const EmptyState = () => (
  <div className="flex flex-col items-center justify-center py-12 text-gray-400">
    <svg 
      className="w-24 h-24 mb-4 text-purple-primary/30 animate-pulse-slow"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"/>
    </svg>
    <h3 className="text-xl font-medium mb-2 text-purple-primary">Nincsenek még jelszavak</h3>
    <p className="text-gray-400 text-center mb-6">
      Kezdj el jelszavakat hozzáadni a <span className="text-purple-light">+</span> gomb segítségével
    </p>
  </div>
);

export const PasswordList: React.FC<PasswordListProps> = ({
  categories,
  tags,
  onCategoryAdded,
  onTagAdded
}) => {
  const { user } = useAuth();
  const { masterPassword, setMasterPassword, lockTimeout } = useMasterPassword();
  const [passwords, setPasswords] = useState<Password[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedPassword, setSelectedPassword] = useState<Password | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [visiblePasswordId, setVisiblePasswordId] = useState<string | null>(null);
  const visiblePasswordTimer = useRef<NodeJS.Timeout>();
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [notification, setNotification] = useState<{
    type: NotificationType;
    message: string;
  } | null>(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [selectedPasswordForShare, setSelectedPasswordForShare] = useState<Password | null>(null);
  const [selectedPasswordDetails, setSelectedPasswordDetails] = useState<Password | null>(null);

  const loadPasswords = async () => {
    if (!user || !masterPassword) return;
    try {
      const fetchedPasswords = await getPasswords(user.uid, masterPassword);
      setPasswords(fetchedPasswords);
    } catch (error) {
      console.error('Hiba a jelszavak betöltése során:', error);
    }
  };

  const loadCategories = async () => {
    if (!user) return;
    try {
      const fetchedCategories = await getCategories(user.uid);
      // Itt nem állítjuk be közvetlenül, mert props-ként kapjuk
    } catch (error) {
      console.error('Hiba a kategóriák betöltése során:', error);
    }
  };

  const loadTags = async () => {
    if (!user) return;
    try {
      const fetchedTags = await getTags(user.uid);
      // Itt nem állítjuk be közvetlenül, mert props-ként kapjuk
    } catch (error) {
      console.error('Hiba a címkék betöltése során:', error);
    }
  };

  const handleRefreshData = () => {
    loadPasswords();
    loadCategories();
    loadTags();
  };

  useEffect(() => {
    const loadData = async () => {
      if (user && masterPassword) {
        try {
          const passwordsData = await getPasswords(user.uid, masterPassword);
          setPasswords(passwordsData);
        } catch (error) {
          console.error('Hiba az adatok betöltése során:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    loadData();
  }, [user, masterPassword]);

  const handleAddPassword = async (passwordData: Omit<Password, 'id' | 'createdAt' | 'updatedAt'>) => {
    if (!masterPassword) return;
    
    try {
      await addPassword(passwordData, masterPassword);
      const updatedPasswords = await getPasswords(user!.uid, masterPassword);
      setPasswords(updatedPasswords);
      setIsAddModalOpen(false);
      setNotification({
        type: 'success',
        message: 'Jelszó sikeresen hozzáadva!'
      });
      setTimeout(() => setNotification(null), 3000);
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Hiba történt a jelszó mentése során.'
      });
      setTimeout(() => setNotification(null), 3000);
    }
  };

  const handleEditPassword = async (
    passwordId: string, 
    updates: Partial<Omit<Password, 'id' | 'createdAt' | 'userId'>>
  ) => {
    if (!masterPassword) return;

    try {
      await updatePassword(passwordId, updates, masterPassword);
      const updatedPasswords = await getPasswords(user!.uid, masterPassword);
      setPasswords(updatedPasswords);
      setIsEditModalOpen(false);
      setNotification({
        type: 'success',
        message: 'Jelszó sikeresen módosítva!'
      });
      setTimeout(() => setNotification(null), 3000);
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Hiba történt a jelszó módosítása során.'
      });
      setTimeout(() => setNotification(null), 3000);
    }
  };

  const handleDeletePassword = async (passwordId: string) => {
    if (!window.confirm('Biztosan törölni szeretnéd ezt a jelszót?')) return;

    try {
      await deletePassword(passwordId);
      const updatedPasswords = await getPasswords(user!.uid, masterPassword!);
      setPasswords(updatedPasswords);
    } catch (error) {
      console.error('Hiba a jelszó törlése során:', error);
    }
  };

  const handleLock = () => {
    setMasterPassword('');
  };

  const handlePasswordVisibility = (passwordId: string) => {
    if (visiblePasswordId === passwordId) {
      setVisiblePasswordId(null);
      if (visiblePasswordTimer.current) {
        clearTimeout(visiblePasswordTimer.current);
      }
      return;
    }

    if (visiblePasswordTimer.current) {
      clearTimeout(visiblePasswordTimer.current);
    }

    setVisiblePasswordId(passwordId);
    visiblePasswordTimer.current = setTimeout(() => {
      setVisiblePasswordId(null);
    }, 10000);
  };

  useEffect(() => {
    return () => {
      if (visiblePasswordTimer.current) {
        clearTimeout(visiblePasswordTimer.current);
      }
    };
  }, []);

  const renderPasswordItem = (password: Password) => {
    const commonContent = (
      <>
        <div className="bg-dark-100 p-4 rounded-lg">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-medium text-gray-300">{password.title}</h3>
            <div className="flex items-center gap-2">
              <button
                onClick={() => setSelectedPasswordDetails(password)}
                className="p-2 text-gray-400 hover:text-purple-light transition-colors duration-200 rounded-full hover:bg-dark-300"
                title="Részletek"
              >
                <InfoIcon className="w-5 h-5" />
              </button>
              <button
                onClick={() => {
                  setSelectedPasswordForShare(password);
                  setIsShareModalOpen(true);
                }}
                className="p-2 text-gray-400 hover:text-purple-light transition-colors duration-200 rounded-full hover:bg-dark-300"
                title="Megosztás"
              >
                <ShareIcon className="w-5 h-5" />
              </button>
              <button
                onClick={() => {
                  setSelectedPassword(password);
                  setIsEditModalOpen(true);
                }}
                className="p-2 text-gray-400 hover:text-purple-light transition-colors duration-200 rounded-full hover:bg-dark-300"
                title="Szerkesztés"
              >
                <EditIcon className="w-5 h-5" />
              </button>
              <button
                onClick={() => handleDeletePassword(password.id)}
                className="p-2 text-gray-400 hover:text-red-500 transition-colors duration-200 rounded-full hover:bg-dark-300"
                title="Törlés"
              >
                <TrashIcon className="w-5 h-5" />
              </button>
            </div>
          </div>

          <div className="flex items-center gap-2 mt-1">
            <div className="flex items-center gap-2">
              <p className="text-gray-400">{password.username}</p>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(password.username);
                  setNotification({
                    type: 'success',
                    message: 'Felhasználónév vágólapra másolva!'
                  });
                  setTimeout(() => setNotification(null), 3000);
                }}
                className="p-1 text-gray-400 hover:text-purple-light transition-colors duration-200 rounded-full hover:bg-dark-300"
                title="Felhasználónév másolása"
              >
                <CopyIcon />
              </button>
            </div>
            {password.ipAddress && (
              <>
                <span className="text-gray-600">•</span>
                <div className="flex items-center gap-2">
                  <p className="text-gray-400 font-mono">{password.ipAddress}</p>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(password.ipAddress!);
                      setNotification({
                        type: 'success',
                        message: 'IP cím vágólapra másolva!'
                      });
                      setTimeout(() => setNotification(null), 3000);
                    }}
                    className="p-1 text-gray-400 hover:text-purple-light transition-colors duration-200 rounded-full hover:bg-dark-300"
                    title="IP cím másolása"
                  >
                    <CopyIcon />
                  </button>
                </div>
              </>
            )}
          </div>

          {password.url && (
            <div className="flex items-center gap-2 mt-1 text-sm">
              <span className="text-gray-400">URL:</span>
              <a
                href={password.url.startsWith('http') ? password.url : `https://${password.url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-light hover:text-purple-primary transition-colors duration-200 
                  hover:underline truncate max-w-[300px]"
                title={password.url}
              >
                {password.url}
              </a>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(password.url!);
                  setNotification({
                    type: 'success',
                    message: 'URL vágólapra másolva!'
                  });
                  setTimeout(() => setNotification(null), 3000);
                }}
                className="p-1 text-gray-400 hover:text-purple-light transition-colors duration-200 rounded-full hover:bg-dark-300"
                title="URL másolása"
              >
                <CopyIcon />
              </button>
            </div>
          )}

          <div className="flex items-center mt-2 space-x-2">
            <div className="relative flex-1">
              {visiblePasswordId === password.id ? (
                <div className="input-dark pr-10 bg-dark-200 w-full py-2 px-3">
                  <ColorCodedPassword password={password.password} />
                </div>
              ) : (
                <input
                  type="password"
                  value={password.password}
                  readOnly
                  className="input-dark pr-10 bg-dark-200"
                />
              )}
              <button
                type="button"
                onClick={() => handlePasswordVisibility(password.id)}
                className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-purple-light transition-colors duration-200"
              >
                {visiblePasswordId === password.id ? <EyeOffIcon /> : <EyeIcon />}
              </button>
            </div>
            <button
              onClick={() => {
                navigator.clipboard.writeText(password.password);
                setNotification({
                  type: 'success',
                  message: 'Jelszó vágólapra másolva!'
                });
                setTimeout(() => setNotification(null), 3000);
              }}
              className="p-2 text-gray-400 hover:text-purple-light transition-colors duration-200 rounded-full hover:bg-dark-300"
              title="Jelszó másolása"
            >
              <CopyIcon />
            </button>
          </div>

          <div className="mt-2 flex flex-wrap items-center gap-2">
            {password.categoryId && (
              <span 
                className="px-2 py-1 text-sm rounded-full bg-dark-300 text-gray-300 border border-dark-400
                  flex items-center gap-1"
              >
                {categories.find(c => c.id === password.categoryId)?.icon}
                {categories.find(c => c.id === password.categoryId)?.name}
              </span>
            )}
            {password.tags.map((tagId) => {
              const tag = tags.find(t => t.id === tagId);
              return tag ? (
                <span 
                  key={tagId}
                  className="px-2 py-1 text-sm rounded-full transition-all duration-200"
                  style={{ 
                    backgroundColor: `${tag.color}20`, 
                    color: tag.color,
                    borderColor: `${tag.color}40`,
                    borderWidth: '1px'
                  }}
                >
                  {tag.name}
                </span>
              ) : null;
            })}
          </div>
        </div>
      </>
    );

    const cardClasses = "card-dark p-4 animate-slide-up w-full";

    return viewMode === 'grid' ? (
      <div className={cardClasses}>
        {commonContent}
      </div>
    ) : (
      <div className={`${cardClasses} mb-4`}>
        {commonContent}
      </div>
    );
  };

  const filteredPasswords = passwords.filter(password => {
    const searchLower = searchTerm.toLowerCase();
    const matchesSearch = 
      password.title.toLowerCase().includes(searchLower) ||
      password.username.toLowerCase().includes(searchLower) ||
      password.url?.toLowerCase().includes(searchLower) ||
      password.ipAddress?.toLowerCase().includes(searchLower) ||
      password.notes?.toLowerCase().includes(searchLower) ||
      categories.find(c => c.id === password.categoryId)?.name.toLowerCase().includes(searchLower) ||
      tags.filter(t => password.tags.includes(t.id)).some(t => t.name.toLowerCase().includes(searchLower));

    const matchesCategory = !selectedCategory || password.categoryId === selectedCategory;
    const matchesTags = selectedTags.length === 0 || 
      selectedTags.every(tagId => password.tags.includes(tagId));

    return matchesSearch && matchesCategory && matchesTags;
  });

  // Keyboard shortcut kezelése
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'l') {
        e.preventDefault();
        handleLock();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleLock]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-purple-primary animate-pulse-slow">Betöltés...</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-0 sm:justify-between mb-6">
          <div className="flex items-center gap-4">
            <h2 className="text-xl sm:text-2xl font-bold text-purple-primary">Jelszavak</h2>
            <div className="flex items-center gap-2">
              {lockTimeout && (
                <div className="hidden sm:flex items-center gap-2 bg-dark-100 px-3 py-1 rounded-full border border-dark-300">
                  <svg 
                    className="w-4 h-4 text-gray-400" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={1.5} 
                      d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8V7a4 4 0 00-8 0v4h8z"
                    />
                  </svg>
                  <span className="text-sm text-gray-400">
                    {lockTimeout}s
                  </span>
                  <div 
                    className="h-1 bg-dark-300 rounded-full w-16"
                    style={{
                      background: `linear-gradient(to right, rgb(139, 92, 246) ${(lockTimeout / 60) * 100}%, rgb(31, 31, 31) 0%)`
                    }}
                  />
                </div>
              )}
              <button
                onClick={handleLock}
                className="p-2 text-gray-400 hover:text-purple-light transition-colors duration-200 
                  rounded-full hover:bg-dark-300 hidden sm:flex group relative"
                title="Zárolás most (⌘L)"
              >
                <LockIcon />
                <span className="absolute bottom-full right-0 mb-2 px-2 py-1 text-xs bg-dark-100 
                  rounded border border-dark-300 opacity-0 group-hover:opacity-100 transition-opacity 
                  duration-200 whitespace-nowrap"
                >
                  {navigator.platform.includes('Mac') ? '⌘L' : 'Ctrl+L'}
                </span>
              </button>
            </div>
          </div>
          
          <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-2 sm:space-x-4">
            <div className="relative flex-1 sm:flex-none">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon />
              </div>
              <input
                type="text"
                placeholder="Keresés..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="input-dark pl-10 pr-4 py-2 w-full sm:w-64 bg-dark-200"
              />
            </div>
            
            <div className="flex justify-between sm:justify-start items-center gap-2">
              <div className="flex bg-dark-200 rounded-lg p-1">
                <button
                  onClick={() => setViewMode('grid')}
                  className={`p-2 rounded-md transition-colors duration-200 ${
                    viewMode === 'grid' 
                      ? 'bg-dark-300 text-purple-primary' 
                      : 'text-gray-400 hover:text-purple-light'
                  }`}
                >
                  <GridIcon />
                </button>
                <button
                  onClick={() => setViewMode('list')}
                  className={`p-2 rounded-md transition-colors duration-200 ${
                    viewMode === 'list' 
                      ? 'bg-dark-300 text-purple-primary' 
                      : 'text-gray-400 hover:text-purple-light'
                  }`}
                >
                  <ListIcon />
                </button>
              </div>
              <button 
                onClick={() => setIsAddModalOpen(true)}
                className="btn-primary"
              >
                <svg 
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M12 4v16m8-8H4" 
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap items-center gap-2 pb-4 border-b border-dark-300 max-w-7xl mx-auto w-full">
          <div className="flex items-center gap-2">
            <button
              onClick={() => setSelectedCategory(null)}
              className={`px-3 py-1.5 text-sm rounded-full transition-colors duration-200
                ${!selectedCategory 
                  ? 'bg-purple-primary text-white' 
                  : 'bg-dark-300 text-gray-400 hover:text-purple-light'
                }`}
            >
              Összes
            </button>
            {categories.map(category => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(
                  selectedCategory === category.id ? null : category.id
                )}
                className={`px-3 py-1.5 text-sm rounded-full transition-colors duration-200
                  flex items-center gap-1
                  ${selectedCategory === category.id
                    ? 'bg-dark-300 text-purple-primary border border-purple-primary'
                    : 'bg-dark-300 text-gray-400 hover:text-purple-light border border-dark-400'
                  }`}
              >
                {category.icon}
                {category.name}
              </button>
            ))}
          </div>

          <div className="flex flex-wrap items-center gap-2">
            {tags.map(tag => (
              <button
                key={tag.id}
                onClick={() => setSelectedTags(prev => 
                  prev.includes(tag.id)
                    ? prev.filter(id => id !== tag.id)
                    : [...prev, tag.id]
                )}
                className={`px-3 py-1.5 text-sm rounded-full transition-all duration-200
                  ${selectedTags.includes(tag.id)
                    ? ''
                    : 'opacity-60 hover:opacity-100'
                  }`}
                style={{ 
                  backgroundColor: `${tag.color}20`,
                  color: tag.color,
                  borderWidth: '1px',
                  borderColor: selectedTags.includes(tag.id) ? tag.color : `${tag.color}40`
                }}
              >
                {tag.name}
              </button>
            ))}
          </div>
        </div>
      </div>

      {filteredPasswords.length === 0 ? (
        searchTerm ? (
          <div className="flex flex-col items-center justify-center py-12 text-gray-400">
            <p className="text-lg">Nincs találat a keresésre</p>
          </div>
        ) : (
          <EmptyState />
        )
      ) : (
        <div className="max-w-7xl mx-auto w-full">
          <div className={
            viewMode === 'grid'
              ? "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
              : "grid grid-cols-1 gap-4"
          }>
            {filteredPasswords.map(password => (
              <div 
                key={password.id} 
                className={
                  viewMode === 'grid'
                    ? "w-full"
                    : "w-full sm:col-span-2 lg:col-span-3"
                }
              >
                {renderPasswordItem(password)}
              </div>
            ))}
          </div>
        </div>
      )}

      <AddPasswordModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSave={handleAddPassword}
        categories={categories}
        tags={tags}
        onCategoryAdded={onCategoryAdded}
        onTagAdded={onTagAdded}
      />

      {selectedPassword && (
        <EditPasswordModal
          isOpen={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
            setSelectedPassword(null);
          }}
          onSave={handleEditPassword}
          password={selectedPassword}
          categories={categories}
          tags={tags}
          onCategoryAdded={onCategoryAdded}
          onTagAdded={onTagAdded}
        />
      )}

      {selectedPasswordForShare && (
        <SharePasswordModal
          password={selectedPasswordForShare}
          onClose={() => {
            setSelectedPasswordForShare(null);
            setIsShareModalOpen(false);
          }}
          onShare={() => {
            setNotification({
              type: 'success',
              message: 'Jelszó sikeresen megosztva!'
            });
            setTimeout(() => setNotification(null), 3000);
            setIsShareModalOpen(false);
          }}
        />
      )}

      {notification && (
        <Notification
          type={notification.type}
          message={notification.message}
          onClose={() => setNotification(null)}
        />
      )}

      {selectedPasswordDetails && (
        <PasswordDetails
          password={selectedPasswordDetails}
          categories={categories}
          tags={tags}
          onClose={() => setSelectedPasswordDetails(null)}
        />
      )}
    </div>
  );
}; 