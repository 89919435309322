import React, { useState, useEffect } from 'react';
import { AuthProvider } from './contexts/AuthContext';
import { Login } from './components/Login';
import { PasswordList } from './components/PasswordList';
import { Settings } from './components/Settings';
import { useAuth } from './contexts/AuthContext';
import { getCategories, getTags } from './services/firebase';
import { Category, Tag } from './types/password';
import { MasterPasswordProvider, useMasterPassword } from './contexts/MasterPasswordContext';
import { Footer } from './components/Footer';
import { MasterPasswordPrompt } from './components/MasterPasswordPrompt';
import { OrganizationList } from './components/organizations/OrganizationList';
import { LanguageProvider, useLanguage } from './contexts/LanguageContext';
import { LanguageSwitcher } from './components/LanguageSwitcher';
import { BetaBanner } from './components/BetaBanner';

type ActiveTab = 'passwords' | 'settings' | 'organizations';

const LogoutIcon = () => (
  <svg 
    className="w-5 h-5" 
    fill="none" 
    stroke="currentColor" 
    viewBox="0 0 24 24"
  >
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth={1.5} 
      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
    />
  </svg>
);

export const AppContent: React.FC = () => {
  const { user, logout } = useAuth();
  const { masterPassword } = useMasterPassword();
  const [activeTab, setActiveTab] = useState<ActiveTab>('passwords');
  const [categories, setCategories] = useState<Category[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useLanguage();

  const loadCategories = async () => {
    if (!user) return;
    try {
      const fetchedCategories = await getCategories(user.uid);
      setCategories(fetchedCategories);
    } catch (error) {
      console.error('Hiba a kategóriák betöltése során:', error);
    }
  };

  const loadTags = async () => {
    if (!user) return;
    try {
      const fetchedTags = await getTags(user.uid);
      setTags(fetchedTags);
    } catch (error) {
      console.error('Hiba a címkék betöltése során:', error);
    }
  };

  const handleDataRefresh = () => {
    loadCategories();
    loadTags();
  };

  useEffect(() => {
    const loadData = async () => {
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        await Promise.all([
          loadCategories(),
          loadTags()
        ]);
      } catch (error) {
        console.error('Hiba az adatok betöltése során:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [user]);

  if (!user) {
    return <Login />;
  }

  if (!masterPassword) {
    return <MasterPasswordPrompt />;
  }

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-dark-200">
        <div className="text-purple-primary animate-pulse-slow">Betöltés...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col bg-dark-200">
      <BetaBanner />
      {masterPassword && (
        <nav className="bg-dark-100 border-b border-dark-300">
          <div className="max-w-7xl mx-auto px-4">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center gap-8">
                <h1 className="text-xl font-bold text-purple-primary">YouPass</h1>
                <div className="hidden sm:flex space-x-4">
                  <button
                    onClick={() => setActiveTab('passwords')}
                    className={`px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200
                      ${activeTab === 'passwords'
                        ? 'bg-dark-300 text-purple-primary'
                        : 'text-gray-400 hover:text-purple-light hover:bg-dark-300'
                      }`}
                  >
                    Jelszavak
                  </button>
                  <button
                    onClick={() => setActiveTab('organizations')}
                    className={`px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200
                      ${activeTab === 'organizations'
                        ? 'bg-dark-300 text-purple-primary'
                        : 'text-gray-400 hover:text-purple-light hover:bg-dark-300'
                      }`}
                  >
                    Szervezetek
                  </button>
                  <button
                    onClick={() => setActiveTab('settings')}
                    className={`px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200
                      ${activeTab === 'settings'
                        ? 'bg-dark-300 text-purple-primary'
                        : 'text-gray-400 hover:text-purple-light hover:bg-dark-300'
                      }`}
                  >
                    Beállítások
                  </button>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <LanguageSwitcher />
                <button
                  onClick={() => {
                    if (window.confirm(t.auth.logoutConfirm)) {
                      logout();
                    }
                  }}
                  className="p-2 text-gray-400 hover:text-purple-light transition-colors duration-200 rounded-full hover:bg-dark-300"
                  title={t.auth.logout}
                >
                  <LogoutIcon />
                </button>
              </div>
            </div>
          </div>
        </nav>
      )}
      <main className="flex-1 w-full px-4 py-6 animate-fade-in">
        {activeTab === 'passwords' ? (
          <PasswordList 
            categories={categories} 
            tags={tags}
            onCategoryAdded={handleDataRefresh}
            onTagAdded={handleDataRefresh}
          />
        ) : activeTab === 'organizations' ? (
          <OrganizationList />
        ) : (
          <Settings 
            categories={categories} 
            tags={tags} 
            onCategoryAdded={handleDataRefresh} 
            onTagAdded={handleDataRefresh}
          />
        )}
      </main>
      {masterPassword && <Footer />}
    </div>
  );
};

const App: React.FC = () => {
  return (
    <LanguageProvider>
      <AuthProvider>
        <MasterPasswordProvider>
          <AppContent />
        </MasterPasswordProvider>
      </AuthProvider>
    </LanguageProvider>
  );
};

export default App;
