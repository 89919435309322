import { db } from '../config/firebase';
import { 
  collection, 
  addDoc, 
  getDocs, 
  query, 
  where,
  Timestamp,
  deleteDoc,
  doc,
  updateDoc,
  getDoc
} from 'firebase/firestore';
import { Organization, OrganizationMember, SharedPassword } from '../types/organization';
import { EncryptionService } from './encryption';

const organizationsCollection = collection(db, 'organizations');
const sharedPasswordsCollection = collection(db, 'sharedPasswords');

// Szervezet létrehozása
export const createOrganization = async (
  organization: Omit<Organization, 'id' | 'createdAt'>
): Promise<string> => {
  const docRef = await addDoc(organizationsCollection, {
    ...organization,
    createdAt: Timestamp.now(),
  });
  return docRef.id;
};

// Szervezetek lekérése ahol tag vagyok
export const getMyOrganizations = async (userId: string): Promise<Organization[]> => {
  // Lekérjük az összes szervezetet
  const snapshot = await getDocs(organizationsCollection);
  
  // Szűrjük azokat, ahol a felhasználó tag vagy tulajdonos
  const myOrgs = snapshot.docs
    .map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        createdAt: data.createdAt.toDate(),
        members: data.members.map((member: any) => ({
          ...member,
          joinedAt: member.joinedAt.toDate()
        }))
      } as Organization;
    })
    .filter(org => 
      org.ownerId === userId || // Tulajdonos vagyok
      org.members.some(member => member.userId === userId) // Tag vagyok
    );

  return myOrgs;
};

// Tag hozzáadása szervezethez
export const addMemberToOrganization = async (
  organizationId: string,
  member: Omit<OrganizationMember, 'joinedAt'>
): Promise<void> => {
  const orgRef = doc(organizationsCollection, organizationId);
  const orgDoc = await getDoc(orgRef);
  
  if (!orgDoc.exists()) throw new Error('Szervezet nem található');
  
  const members = orgDoc.data().members || [];
  members.push({
    ...member,
    joinedAt: Timestamp.now()
  });
  
  await updateDoc(orgRef, { members });
};

// Jelszó megosztása szervezettel
export const sharePasswordWithOrganization = async (
  sharedPassword: Omit<SharedPassword, 'id'>,
  masterPassword: string
): Promise<void> => {
  const encryptedPassword = await EncryptionService.encrypt(
    sharedPassword.password,
    masterPassword
  );
  
  await addDoc(sharedPasswordsCollection, {
    ...sharedPassword,
    password: encryptedPassword,
    sharedAt: Timestamp.fromDate(sharedPassword.sharedAt)
  });
};

// Megosztott jelszavak lekérése
export const getSharedPasswords = async (
  organizationId: string,
  masterPassword: string
): Promise<SharedPassword[]> => {
  const q = query(
    sharedPasswordsCollection, 
    where("organizationId", "==", organizationId)
  );
  const snapshot = await getDocs(q);
  
  const passwords = await Promise.all(
    snapshot.docs.map(async doc => {
      const data = doc.data();
      try {
        const decryptedPassword = await EncryptionService.decrypt(
          data.password,
          masterPassword
        );
        
        return {
          id: doc.id,
          ...data,
          password: decryptedPassword,
          sharedAt: data.sharedAt.toDate(),
          createdAt: data.createdAt.toDate(),
          updatedAt: data.updatedAt.toDate(),
        } as SharedPassword;
      } catch (error) {
        console.error(`Hiba a megosztott jelszó visszafejtése során (${doc.id}):`, error);
        return null;
      }
    })
  );

  return passwords.filter((p): p is SharedPassword => p !== null);
};

// Szervezet törlése
export const deleteOrganization = async (organizationId: string): Promise<void> => {
  // Először töröljük a szervezethez tartozó megosztott jelszavakat
  const sharedPasswordsQuery = query(
    sharedPasswordsCollection, 
    where("organizationId", "==", organizationId)
  );
  const sharedPasswordsSnapshot = await getDocs(sharedPasswordsQuery);
  
  await Promise.all(
    sharedPasswordsSnapshot.docs.map(doc => deleteDoc(doc.ref))
  );

  // Majd töröljük magát a szervezetet
  await deleteDoc(doc(organizationsCollection, organizationId));
};

// Szervezet módosítása
export const updateOrganization = async (
  organizationId: string,
  updates: Partial<Omit<Organization, 'id' | 'createdAt'>>
): Promise<void> => {
  const orgRef = doc(organizationsCollection, organizationId);
  await updateDoc(orgRef, updates);
}; 