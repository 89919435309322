import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

export const LanguageSwitcher: React.FC = () => {
  const { language, setLanguage } = useLanguage();

  return (
    <div className="flex items-center gap-2">
      <button
        onClick={() => setLanguage('hu')}
        className={`p-1 rounded transition-all duration-200 ${
          language === 'hu' ? 'bg-dark-300' : 'hover:bg-dark-200'
        }`}
        title="Magyar"
      >
        🇭🇺
      </button>
      <button
        onClick={() => setLanguage('en')}
        className={`p-1 rounded transition-all duration-200 ${
          language === 'en' ? 'bg-dark-300' : 'hover:bg-dark-200'
        }`}
        title="English"
      >
        🇬🇧
      </button>
    </div>
  );
}; 