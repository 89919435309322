import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  // Itt add meg a Firebase konfigurációs adataidat
  apiKey: "AIzaSyCm5B1Kt77t0fs-9tq5vCodVUHZLjE6zOU",
  authDomain: "youpass-a3241.firebaseapp.com",
  projectId: "youpass-a3241",
  storageBucket: "youpass-a3241.firebasestorage.app",
  messagingSenderId: "680451299504",
  appId: "1:680451299504:web:891b3845406ca498cf0375",
  measurementId: "G-NLB5ZHVVF8"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app); 